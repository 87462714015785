.payment-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 5rem;
  box-sizing: border-box;
  .payment-status{
    .img{
      height: 231px;
      img{
        display: block;
        height: 100%;
      }
    }
    .message{
      font-size: 1.8rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #666666;    
      margin: 2rem 0;
      text-align: center;
    }
    .continew-btn{
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      border-radius: 4px;
      padding: 0.8rem 2rem;
      cursor: pointer;
      &.success{
        background-color: #008f21;
      }
      &.error{
        background-color: #fc5050;
      }
      &:focus{
        outline: none;
      }
    }
  }
}