.tester-dash-home {

  .searchResultsContainer{
  .card{
    button{
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      background-color: #133ee9;
      font-size: 0.9rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
      }
  }
  .tester-dashbaord-banner{
    max-width: 1300px;
    img{
      display: block;
      width: 100%;
      border-radius: 10px;
    }
  }
  .dash-live-project-card {
    margin: 2rem 0;
    max-width: 1300px;
    margin-top: 0.8rem;
    .dash-live-project-card-cards {
      .live-project-card-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.7rem;
        width: 100%;
        margin-top: 0.5rem;
        padding: 0.9rem 0.5rem;
      }
    }
    .select-project {
      position: absolute;
      background-color: #fff;
      padding: 0px 20px 20px 20px;
      width: 497px;
      height: 240px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #e4e4e4;
      }
      &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }

      .select-project-item {
        font-size: 0.9rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 1rem 0;
        border-bottom: 1px solid #d3d3d3;
        cursor: pointer;
        display: flex;
        align-items: center;
        .selector {
          width: 19px;
          height: 19px;
          box-sizing: border-box;
          border: solid 1px #2896e9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }
        &:hover {
          .selector .selector-inside {
            height: 15px;
            width: 15px;
            background-image: linear-gradient(224deg, #2896e9 88%, #7467f0 11%);
            border-radius: 50%;
          }
        }
      }
    }
    .displayNone {
      display: none;
    } 
    .card {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.7rem;
      border-bottom: 2px solid #d6d8d9;
      width: 500px;
      .img {
        height: 17px;
      }
      input {
        display: block;
        margin-left: 1rem;
        font-size: 1.2rem;
        width: 100%;
        border: none;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
    }
    #searchbar{width: 100%;max-width: 500px;}
  }
  .dash-home-leaderboard {
    margin: 2rem 0;
    max-width: 1300px;
    margin-top: 0.8rem;
    .dash-home-leaderboard-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      width: 100%;
      margin-top: 0.5rem;
      padding: 0.9rem 0.5rem;
    }
  }
  .records {
    margin-bottom: 1rem;
    .cards {
      display: flex;
      overflow-x: auto;
      padding: 0.9rem 0.5rem;
    }
  }
  .live-projects {
    padding-top: 0.5rem;
    padding-bottom: 3rem;
  }
}

.dash-home-advertising {
  width: 1300px;
  height: 144px;
  padding: 20px;
  text-align: center;
  background-color: rgb(61, 58, 241);
  border-radius: 10px;
  box-sizing: border-box;
  margin: 1rem 0;
  &-wrapper {
    display: flex;
    margin: auto;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  &-title {
    font-size: 33px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
  }
  .click-here-button {
    width: 184px;
    height: 46px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    border-radius: 10px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    outline: none;
    border: none;
  }
}

@media only screen and (max-width: 1670px) {
  .tester-dash-home {
    .dash-live-project-card {
      .dash-live-project-card-cards {
        .live-project-card-cards {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .tester-dash-home {
    .dash-live-project-card {
      .dash-live-project-card-cards {
        .live-project-card-cards {
          grid-template-columns: repeat(2, 1fr);
        }
        
      }
    }
    .dash-home-leaderboard {
      .dash-home-leaderboard-cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .tester-dash-home {
    .tester-dashbaord-banner {
      display: none;
    }
    .dash-live-project-card {
      
      .dash-live-project-card-cards {
        overflow-x: scroll;
        .live-project-card-cards {
          display: flex;
        }
        
      }
    }

    .dash-home-leaderboard {
      .dash-home-leaderboard-cards {
        display: flex;
        width: auto;
        overflow-x: scroll;
        gap: 1.7rem;
      }
    }
  }
}

