.none {
  display: none;
}

.updateClient-modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 99;
  .updateClient-modal {
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2rem 1.9rem;
    box-sizing: border-box;
    min-width: 700px;
    .close-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .close {
        width: max-content;
        cursor: pointer;
      }
    }
    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.6rem;
      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
        margin: 0;
        padding-bottom: 18px;
      }
      h4 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: #393939;
      }
    }

    .body-container {
      padding-bottom: 1.5rem;
      .body {
        .updateClientPopUpContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .updateClientPopUp {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            background-color: #fff;
            padding: 2rem;
            border-radius: 0.5rem;
            width: 500px;

            .updateClientPopUpRow {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 2rem;
              margin-bottom: 1.5rem;

              .updateClientPopUpLabel {
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #505050;
              }

              .updateClientPopUp-input {
                width: 100%;
                background-color: #e6e6e6;
                border: none;
                color: #505050;
                outline: none;
                line-height: 2;
                font-size: 1rem;
              }

              &.leftAlign {
                justify-content: flex-start;
                padding-left: 2rem;
                .upload-file {
                  max-width: 300px;
                  label {
                    width: 100%;
                    margin: 0;
                    box-sizing: border-box;
                    text-align: center;
                    border-radius: 4px;
                    padding: 0.7rem 2rem;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: normal;
                    color: #ffffff;
                    cursor: pointer;
                    word-wrap: break-word;
                    &.blue {
                      background-color: #529cf7;
                    }
                  }
                  input {
                    visibility: hidden;
                    width: 0px;
                    height: 0px;
                  }

                  .grid {
                    box-sizing: border-box;
                    margin-top: 2rem;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                      margin: 0;
                    }
                  }

                  .addtest-input-label {
                    font-size: 0.8rem;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.23;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a4a4a4;
                    display: block;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 0.8rem;
                  }
                }
              }
            }

            .updateClientPopUpRowButtons {
              margin-top: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              button {
                width: 200px;
                height: 41px;
                background-color: #fff;
                cursor: pointer;
                font-size: 1rem;
                font-weight: 700;
                border-radius: 2px;
                outline: none;
                border: none;
                padding: 0;
                margin: 0;
                &.no {
                  color: #505050;
                  border: solid 1px #ff3400;
                }
                &.yes {
                  color: #fff;
                  background-color: #ff3400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .updateClient-modal-container {
    position: fixed;
    justify-content: center;
    align-items: center;
    margin: 20px;
    overflow-y: scroll;
    //padding: 150px 0px;
    .updateClient-modal {
      min-width: 300px;
      height: auto;
      width: 99%;
      margin-top: 20vh;
      margin-bottom: 5vh;
      padding: 15px 15px 15px 15px;
      border-radius: 25px;

      .heading {
        margin: 0;
      }

      .body-container {
        .body {
          .updateClientPopUpContainer {
            .updateClientPopUp {
              padding: 0;
              .updateClientPopUpRow {
                flex-direction: column;
              }
              .updateClientPopUpRowButtons {
                flex-direction: column;
                gap: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
