.text-bug-list {
  .buttons-container {
    display: flex;
    max-width: 1350px;
    justify-content: flex-end;
    align-items: center; 
    column-gap: 20px;
    .search-tester {
      width: 200px;
      border: none;
      outline: none;
      border-bottom: 1px solid #505050;
      padding-bottom: 5px; 
      background-color: transparent;
    } 
    .active-clients {
      padding: 12px 20px;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      // margin-bottom: 1.5rem;
      margin-left: 1rem;
      &.blue {
        background-color: #1c76b2;
      }
      &.orange {
        background-color: #fd6529;
      }
    }
  }
}

@media   (max-width: 786px) {
  .text-bug-list{
    .buttons-container{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr); 
      justify-content: center;
      row-gap: 20px;
      // max-width: 0px;
      width: 90%; 
      .search-tester{
        width: 100%; 
      }
      .active-clients{
        margin-left: 0;
        width: 100%; 
      }
    } 
  } 
} 