.my_earnings{
  width: 987px;
  margin-bottom: 3rem;
  .current-balance-container{
      margin-top: 2rem;
      margin-bottom: 2.5rem;
      .current-balance-label{
          font-size: 0.8rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #a4a4a4;
          margin-bottom: 0.5rem;
      }
      .current-balance{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .transfer-amount{
              font-size: 30px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.23;
              letter-spacing: normal;
              text-align: left;
              color: #001464;  
              margin-right: 0.7rem;            
          }
          .message{
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #505050;              
              margin: 0.5rem 0.7rem;        
          }
          .request-transfer{
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.31;
              letter-spacing: normal;
              text-align: left;
              color: #ffffff;
              border-radius: 4px;
              background-color: #00b212;
              padding: 0.7rem 1.5rem;
              margin: 0 0.7rem;         
              cursor: pointer;   
          }
      }

  }

}
.tester-pricing {
    .terms-and-conditions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1350px;
      p{
        font-size: 1.1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #565656;
        width: 73%;
      }
      h6 {
        text-align: right;
        font-size: 0.7rem;
        a {
          text-decoration: none;
          color: #0000ff;
        }
      }
    }
    
  }
  @media screen and (min-width: 900px) {
   .tester-pricing{
     .terms-and-conditions{
       p{
         width: 80%;
       }
     }
   }
  }

@media  screen and (max-width: 1200px){
    .my_earnings{
        width: 100%;
    }
}

@media  screen and (max-width: 786px){
    .my_earnings{
        width: 100%;
    }
}