.client-ongoing-card,
.client-completed-card,
.client-paused-card {
  box-sizing: border-box;
  padding: 2.5rem 2rem;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 23.5%;
  .project-img {
    width: 162px;
    height: 70px;
    max-width: 200px;
    max-height: 70px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      max-width: 100%;
      height: 100%;
    }
  }
  .project-detail {
    text-align: center;
    h4 {
      margin: 15px 0px;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #a4a4a4;
    }
    h2 {
      margin: 0px;
      padding-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #505050;
      border-bottom: 1px solid #d8d8d8;
      width: 213px;
      margin-bottom: 1.2rem;
      .more-less-butn {
        background-color: none;
        background: none;
        border: none;
        outline: none;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .testing-detail {
    display: flex;
    h4 {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #a4a4a4;
      min-width: 80px;
    }
    h2 {
      margin: 0px;
      margin-left: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
    }
  }

  .bluedetail-btn {
    padding: 12px 43px 12px 44px;
    border-radius: 4px;
    background-color: var(--glb-btn-general);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 35px;
  }

  .greendetail-btn {
    padding: 12px 43px 12px 44px;
    border-radius: 4px;
    background-color: #2e8000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 35px;
  }
}
@media screen and (max-width: 1450px) {
  .client-ongoing-card,
  .client-completed-card,
  .client-paused-card {
    width: 23.2%;
  }
}
@media screen and (max-width: 1228px) {
  .client-ongoing-card,
  .client-completed-card,
  .client-paused-card {
    width: 31.5%;
  }
}

@media screen and (max-width: 767px) {
  .client-ongoing-card,
  .client-completed-card,
  .client-paused-card {
    width: 100%;
  }
}
