.none {
  display: none;
}
.transfer-amount-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 2rem;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  .transfer-amount-modal-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    width: 500px;
    padding: 1.2rem 1.5rem;
    padding-top: 2rem;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid #dcdcdc;
      .text {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #313131;
      }
      .close-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        svg {
          fill: #acacac;
          height: 22px;
          width: 22px;
        }
        &:hover {
          svg {
            fill: #7c7c7c;
          }
        }
      }
    }
    .body {
      margin-top: 1.3rem;
      .display-data {
        margin-bottom: 1rem;
        .label {
          font-size: 0.8rem;
          color: #a4a4a4;
        }
        .data {
          font-size: 1rem;
          color: #505050;
        }
      }
    }
    .submit {
      box-sizing: border-box;
      padding: 1.3rem;
      padding-bottom: 0.5rem;
      button {
        border-radius: 4px;
        background-color: #008716;
        width: 100%;
        padding: 0.6rem;
        box-sizing: border-box;
        border: none;
        text-align: center;
        color: #ffffff;
        font-size: 1.1rem;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .transfer-amount-modal {
    width: 100%;
    .transfer-amount-modal-container {
      width: 300px;
    }
  }
}
