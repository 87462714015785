.none {
  display: none;
}
.cursor {
  cursor: pointer;
}
.header-main {
  .header-main-container {
    display: flex;
    align-items: center;
    padding: 0.8rem 4rem;
    position: fixed;
    height: 84px;
    width: 100vw;
    box-sizing: border-box;
    top: 0;
    z-index: 99;

    .header-logo-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 24px;
      .header-logo {
        font-weight: bold;
        cursor: pointer;
        .logo {
          height: 40px;
          width: auto;
          img {
            display: block;
            height: 100%;
          }
        }
      }
    }
    .active a {
      color: #247cd3;
    }
    .inActive a {
      color: #565656;
    }
    .header-navitems-container {
      flex: 4;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        font-weight: 500;
        li {
          list-style: none;
          padding: 0 25px;
          cursor: pointer;
          font-weight: 700;
          font-size: 1rem;
          &:hover {
            color: #247cd3;
          }
        }
      }
    }

    .header-control-section {
      ul {
        margin: 0;
        padding: 0;
        li {
          font-size: 1rem;
          font-weight: 700;
          color: #247cd3;
          .inherit {
            font-weight: 700;
          }
        }
      }
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .login-user {
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          margin-right: 0.5rem;
          img {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            border: 1px solid #cacacab7;
          }
          .testerBadge {
            width: 22px;
            height: 22px;
            position: relative;
            float: right;
            top: -30%;
            left: 10%;
            border: none;
          }
        }
        h4 {
          font-size: 1rem;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #565656;
          cursor: pointer;
        }
      }
      .hamberger {
        display: none;
        img {
          cursor: pointer;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          list-style: none;
          padding: 0 15px;
          font-weight: 500;
        }
        .hamberger {
          display: none;
        }
      }
      .signup-tester {
        background-image: url("../../../assets/images/Rectangle16909.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 250px;
        height: auto;
        text-align: center;
        border-radius: 75px;
        padding: 10px;
        font-size: 22px;
        color: var(--glb-txt-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .link-blue {
      color: #247cd3;
    }
    .blue {
      color: #103dfa;
    }
    .white {
      color: var(--glb-txt-primary);
    }
    .grey {
      color: #505050;
    }
  }
  .header-navitems-container-responsive {
    display: none;
  }

  .bg-white {
    //background-color: var(--glb-white);
    background-color:#eeeeee;
    box-shadow: var(--glb-shadow);
  }
}

.dropdown{
  position: relative;
}

.dropdown:hover > .dropdownItems{
  opacity: 1;
  visibility: visible;
  width: 282px;
}

@media only screen and (max-width: 1440px) {
  .header-main {
    .header-main-container {
      padding: 0.7rem 3.5rem;
      height: 70px;
      .header-logo-container {
        .header-logo {
          .logo {
            height: 35px;
          }
        }
      }
      .header-control-section {
        .login-user {
          .img {
            height: 45px;
            width: 45px;
            cursor: pointer;
            .testerBadge {
              width: 22px;
              height: 22px;
              position: relative;
              float: right;
              top: -30%;
              left: 10%;
              border: none;
            }
          }
          h4 {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .header-main {
    .header-main-container {
      padding: 0.8rem 1.5rem;
      .header-logo-container {
        .header-logo {
          .logo {
            height: 25px;
            width: auto;
            img {
              display: block;
              height: 100%;
            }
          }
        }
      }

      .header-navitems-container {
        .header-item {
          display: none;
        }
      }

      .responsive {
        margin-top: 150px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transition: 0s;
        ul {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          font-weight: 500;
          li {
            list-style: none;
            padding: 0 25px;
            cursor: pointer;
          }
        }
        .header-item {
          display: flex;
          gap: 20px;
        }
      }
      .header-control-section {
        max-width: max-content;
        .header-item {
          display: none;
        }
        .hamberger {
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-user {
          display: none;
        }

        ul {
          .hamberger {
            display: block;
          }
        }
      }
    }
    .fadeIn {
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
    }
    .header-navitems-container-responsive {
      width: 100%;
      position: fixed;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      -webkit-animation-duration: 0.2s;
      animation-duration: 0.2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      z-index: 999;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0;
        font-weight: 500;
        li {
          list-style: none;
          padding: 15px 25px;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 700;
          color: #505050;
        }
      }
      .signup-tester {
        background-image: url("../../../assets/images/Rectangle16909.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 200px;
        height: 30px;
        text-align: center;
        border-radius: 100px;
        padding-top: 10px;
        padding-bottom: 0px;
        font-size: 18px;
        color: var(--glb-txt-primary);
      }
      .login-user-responsive {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .login-user {
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            height: 53px;
            width: 53px;
            border-radius: 50%;
            // margin-right: 0.5rem;
            margin: auto;
            margin-bottom: 25px;
            img {
              display: block;
              border-radius: 50%;
              width: 100%;
              height: 100%;
              border: 1px solid #cacacab7;
            }
            .testerBadge {
              width: 22px;
              height: 22px;
              position: relative;
              float: right;
              top: -30%;
              left: 10%;
              border: none;
            }
          }
          h4 {
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            text-align: center;
            color: #565656;
          }
        }
        h4 {
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #565656;
        }
      }

      .link-blue {
        color: #247cd3;
      }
      .blue {
        color: #103dfa;
      }
      .white {
        color: var(--glb-txt-primary);
      }
      .grey {
        color: #505050;
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
