.tester_banner-main-container {
  height: 100vh;
  padding: 0 60px;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  //background: url("../../../assets/images/banner-bg.png") no-repeat center center/cover;
  color: var(--glb-txt-primary);
  .tester_banner-details-container {
    display: flex;
    .tester_banner-details {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .tester_banner-text {
        font-size: 60px;
        font-weight: bold;
        //height: 69px;
        span {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
        }
        .txt-type {
          font-size: inherit;
          font-weight: inherit;
          .txt {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
      .div-yellow {
        color: var(--glb-blue);
      }
      .div-white {
        color: #ffffff;
      }
      span {
        font-size: 24px;
        margin: 30px 0 30px;
      }
      .tester_banner-button-flex {
        display: flex;
        justify-content: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .tester_banner-button-tester {
          display: none;
        }
        .tester_banner-button-client {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #ffffff;
          border-radius: 4px;
          border: 1px solid white;
          margin: 30px 75px 75px 0;
          padding: 20px 0;
          border-radius: 4px;
          border: solid 1.5px #ffffff;
          font-size: 28px;
          width: 40%;
          text-align: center;
          cursor: pointer;

        }
      }
    }
    .tester_banner-image-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 70%;
        //border-radius: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1470px) {
  .tester_banner-main-container {
    .tester_banner-details-container {
      .tester_banner-details {
        width: 100%;
        .tester_banner-text {
          font-size: 50px;
        }
        .tester_banner-button-flex {
          .tester_banner-button-tester {
            display: none;
          }
          .tester_banner-button-client {
            font-size: 20px;
            padding: 20px 30px 19px 30px;
          }
        }
      }
      .tester_banner-image-container {
        width: 100%;
      }
    }
  }
}
@media (max-width: 786px) {
  .tester_banner-main-container {
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
    //padding-top: 100px;
    .tester_banner-details-container {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .tester_banner-details {
        display: flex;
        flex: none;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        .tester_banner-text {
          font-size: 32px;
        }
        .div-yellow {
          font-size: 42px;
        }
        .tester_banner-button-flex {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: auto;
          margin-left: auto;
          .tester_banner-button-tester {
            display: none;
          }
          .tester_banner-button-client {
            margin: 30px auto 30px auto;
            width: 50%;
            text-align: center;
          }
        }
      }
      .tester_banner-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
        img {
          width: 80%;
          height: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
