.test-resources-list-main-container {
  width: 40%;
  .test-resources-list-item {
    border-radius: 4px;
    border: solid 1px #d4dfff;
    background-color: #ffffff;
    .test-resources-list-item-title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
    }
    .test-resources-list-item-table {
      .table-header {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: start;
        color: #a4a4a4;
        padding: 10px;
      }
      .table-data {
        text-align: center;
        font-size: 12px;
        .button {
          width: max-content;
          border-radius: 4px;
          color: var(--glb-txt-primary);
          padding: 10px;
          margin: 0 10px;
        }
        .green {
          background-color: #007a04;
        }
        .lg-blue {
          background-color: #529cf7;
        }
        .url-input {
          padding: 8px;
          margin: 10px;
          border: 0;
          border-bottom: 1px solid gray;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
