.manageTestimonialss {
  h2 {
    margin-bottom: 0;
    margin-top: 50px;
    text-align: center;
    font-size: 36px;
  }
  .testimonials-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    .element {
      box-sizing: border-box;
      text-align: center;
      margin: 2rem 5px;
      cursor: pointer;
      padding: 0.5rem;
      img {
        width: 50px;
      }
      &:hover {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--glb-white);
        transition: linear 0.2s;
      }
    }
    .approvedTestimonials-container {
      box-sizing: border-box;
      overflow-x: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      .approvedTestimonialss {
        box-sizing: border-box;
        width: 100%;
        height: 500px;
        display: flex;
        gap: 21px;
        padding: 0px 10px;
        align-items: center;
        transition: 0.5s;
      }
    }
  }
}

// @media   (max-width: 767px) {
//     .manageTestimonialss {
//       .testimonials-container {
//         position: relative;
//         .element {
//           padding: 0px;
//           position: absolute;
//         }
//         .left{

//           z-index: 1;
//         }
//         .right{
//           left: 75%;
//         }
//       }
//     }
//   }
