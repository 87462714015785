.payment-processing-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .payment-processing-popup {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    padding: 2rem;
    .heading {
      font-size: 2rem;
      color: #444444;
      text-align: center;
    }
    .message {
      margin-top: 1rem;
      text-align: center;
      color: #444444;
      font-size: 1rem;
    }
  }
}
