.cards-grid-01 {
  h4 {
    font-size: 36px;
    color: var(--glb-dark-blue); //var(--heading-dark);
    line-height: 44px;
  }
  .custom-container {
    padding-bottom: 5rem;
    .custom-row-01 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      padding: 1rem;
    }
    .custom-row-001 {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .image-container {
    .tester_floating-cards {
      padding: 10px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 90px;
    }
  }
  .tester_item--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2rem;
    text-align: center;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: var(--glb-shadow);
    border: var(--glb-border);
    span {
      display: block;
      font-weight: bold;
      color: #5a5a5a;
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      letter-spacing: normal;
      text-align: center;
    }
    .whyQantily_heading {
      margin: 0;
      margin-top: 20px;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: var(--glb-dark-blue);
    }
    .whyQantily_description {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
      padding: 0.5rem 2rem;
    }
  }
  .description-container {
    text-align: center;
    padding-bottom: 2.5em;
    span {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
  }
  .btn-container {
    padding-top: 4em;
    padding-bottom: 2em;
    button {
      width: 235px;
      max-width: 234px;
      // height: 51px;
      // border-radius: 4px;
      // border: none;
      // color: var(--glb-txt-primary);
      // background-color: var(--glb-btn-general); //var(--theme-dark-btn);
      // font-size: 18px;
      // cursor: pointer;
      // &:focus {
      //   outline: none;
      // }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .cards-grid-01 {
    .image-container {
      .tester_floating-cards {
        background: #fff;
        //box-shadow: var(--glb-shadow);
        padding: 15px;
        border-radius: 50%;
        margin-bottom: 10px;
        margin: 25px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (max-width: 786px) {
  .cards-grid-01 {
    .custom-container {
      .custom-row-01 {
        gap: 20px;
        grid-template-columns: 1fr;
      }
      .custom-row-001 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.7rem;
      }
    }
  }
}
