.banner-main-container {
  height: 100vh;
  padding: 0 60px;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // background: url("../../../assets/images/banner-bg.png") no-repeat center center/cover;
  background-color: var(--glb-bg-secondary);
  color: var(--glb-txt-primary);
  .banner-details-container {
    display: flex;
    .banner-details {
      flex: 1.1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .banner-text {
        font-size: 55px;
        font-weight: bold;
        min-height: 69px;
        .txt-type {
          font-size: inherit;
          font-weight: inherit;
          .txt {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
      .div-yellow {
        color: var(--glb-blue);
      }
      span {
        font-size: 24px;
        margin: 30px 0 30px;
      }
      .banner-button-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .banner-button-client,
        .banner-button-tester {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          // text-decoration: none;
          // color: #ffffff;
          // border-radius: 4px;
          // border: 1px solid white;
          // margin: 30px 75px 75px 0;
          // padding: 20px 0;
          // border-radius: 4px;
          // border: solid 1.5px #ffffff;
          // font-size: 28px;
          width: 45%;
          // text-align: center;
          // cursor: pointer;
          // &:hover {
          //   color: var(--glb-blue);
          //   background-color: var(--glb-white);
          //   // font-weight: bold;
          //   border: 1px solid var(--glb-blue);
          // }
        }
      }
    }
    .banner-image-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 5rem;
      img {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 1470px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        width: 100%;
        .banner-text {
          font-size: 50px;
        }
        .banner-button-flex {
          .banner-button-client,
          .banner-button-tester {
            font-size: 20px;
            padding: 20px 30px 19px 30px;
          }
        }
      }
      .banner-image-container {
        width: 100%;
      }
    }
  }
}
@media (max-width: 786px) {
  .banner-main-container {
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 75px 0;
    margin-top: 64px;
    //padding-top: 75px;
    .banner-details-container {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .banner-details {
        display: flex;
        flex: none;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        .banner-text {
          font-size: 32px;
        }
        .div-yellow {
          font-size: 42px;
        }
        .banner-button-flex {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: auto;
          margin-left: auto;

          .banner-button-client,
          .banner-button-tester {
            margin: 15px auto 15px auto;
            width: 75%;
            text-align: center;
            font-size: 1.2rem;
          }
        }
      }
      .banner-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
        margin-top: 15px;
        img {
          width: 80%;
          height: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media (max-width: 1224px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 48px;
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 45px;
        }
      }
    }
  }
}
@media (max-width: 1114px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 42px;
        }
      }
    }
  }
}
@media (max-width: 1048px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 40px;
        }
      }
    }
  }
}
@media (max-width: 1004px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 38px;
        }
      }
    }
  }
}
@media (max-width: 525px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 35px;
        }
      }
    }
  }
}
@media (max-width: 484px) {
  .banner-main-container {
    .banner-details-container {
      .banner-details {
        .banner-text {
          font-size: 32px;
        }
      }
    }
  }
}
