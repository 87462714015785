.dash-nav-comp {
  max-width: 100%;
  box-sizing: border-box;
  z-index: 5;
  padding: 0.3rem 0.5rem;
  .nav-heading {
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    cursor: default;
    .icon {
      display: none;
      height: 25px;
      width: 25px;
      margin-right: 0.5rem;
      img {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
    .nav-heading-text {
      font-size: 1.4rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #172b4d;
    }
  }
  .nav-items {
    box-sizing: border-box;
    .item {
      padding: 1rem;
      margin-bottom: 0.3rem;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      &.imp {
        background-image: url("../../assets/images/boostMultiplierBg.png");
        background-repeat: no-repeat;
        background-size: 100%;

        h3 {
          color: #ffffff;
        }
        &:hover {
          background-image: url("../../assets/images/boostMultiplierBg.png");
          box-shadow: var(--glb-shadow);
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .alert-icon {
        margin-left: auto;
        margin-right: 1.5rem;

        img {
          display: block;
          height: 100%;
        }
        .new-notification {
          font-size: 0.75rem;
          font-weight: bolder;
        }
      }
      .nav-icon {
        color: #172b4d;
        font-size: 1.7rem;
        padding-right: 0.7rem;
      }

      .nav-icon-svg {
        svg {
          height: 29px;
          padding-left: 0.4rem;
          padding-right: 0.7rem;
          fill: #172b4d;
          stroke: #172b4d;
        }
      }

      cursor: pointer;
      h3 {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #17214f;
      }
      &:hover {
        background: #dddddd;
      }
      &.active {
        background: #123dfb;
        h3 {
          color: #ffffff;
        }
        .nav-icon {
          color: #ffffff;
          padding-right: 0.7rem;
        }
        .nav-icon-svg {
          svg {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
    }
    .telegram-button {
      background-color: #2fa7db;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
    }
    .telegram-button a {
      text-decoration: none;
      color: #ffffff;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 1900px) {
  .dash-nav-comp {
    .nav-heading {
      cursor: pointer;
      .icon {
        display: block;
        height: 25px;
        width: 25px;
      }
      .nav-heading-text {
        font-size: 1.4rem;
      }
    }
    .nav-items {
      .item {
        padding: 0.5rem;
        &.imp {
          background-size: auto;
          &:hover {
            background-size: auto;
          }
        }
        h3 {
          font-size: 12px;
        }
        // .alert-icon{
        //   height: 20px;
        //   width: 20px;
        // }
        .nav-icon {
          font-size: 1.3rem;
        }
        .nav-icon-svg {
          svg {
            height: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .dash-nav-comp {
    .nav-heading {
      padding-top: 1rem;
    }
    .nav-items {
      .item {
        padding: 1rem;
        h3 {
          font-size: 1rem;
        }
        // .alert-icon{
        //   height: 26px;
        //   width: 26px;
        // }
        .nav-icon {
          font-size: 1.6rem;
        }
        .nav-icon-svg {
          svg {
            height: 26px;
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .dash-nav-comp {
    .nav-items {
      .item {
        &.imp {
          background-size: auto;
          &:hover {
            background-size: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 1040px) {
  .dash-nav-comp {
    .nav-items {
      .item {
        &.imp {
          background-size: 100%;
          &:hover {
            background-size: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 1040px) and (max-width: 1100px) {
  .dash-nav-comp {
    .nav-heading {
      .nav-heading-text {
        font-size: 1.1rem;
      }
    }
  }
}
