.privacy {
  margin: 50px 100px;
  margin-top: 150px;
  text-align: justify;
}

@media (max-width: 786px) {
  .privacy {
    margin: 0px 25px;
    margin-top: 75px;
  }
}