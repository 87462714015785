.client-dashobard-all-projects {
  .client-dashboard-projects {
    display: flex;
    align-items: center;
    max-width: 1290px;
    box-sizing: border-box;
    .element {
      box-sizing: border-box;
      text-align: center;
      margin: 2rem 5px;
      cursor: pointer;
      padding: 0.5rem;
      img {
        width: 50px;
      }
      &:hover {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--glb-white);
        transition: linear 0.2s;
      }
    }
    .project-container {
      box-sizing: border-box;
      overflow-x: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      .client-dashboard-projects-cards {
        box-sizing: border-box;
        width: 100%;
        height: 500px;
        display: flex;
        gap: 21px;
        padding: 0px 10px;
        align-items: center;
        transition: 0.5s;
      }
    }
  }
}

@media (max-width: 767px) {
  .client-dashobard-all-projects {
    .sidebarDashboard {
      display: block;
      h2 {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.32;
        color: #172b4d;
        padding: 0;
        margin: 10;
      }
    }
    .client-dashboard-projects {
      position: relative;
      .element {
        padding: 0px;
        position: absolute;
      }
      .left {
        z-index: 1;
      }
      .right {
        left: 75%;
      }
    }
  }
}
