.tester-details {
  .tester-details-header {
    .tester-details-header-name {
      font-size: 26px;
      font-weight: bold;
      color: #247dd2;
    }
    .tester-details-header-email {
      font-family: Roboto;
      font-size: 14px;
      color: #818181;
    }
    .tester-details-header-reset-pass {
      font-size: 12px;
      color: #ffffff;
      padding: 10px 23px;
      border-radius: 4px;
      background-color: #32bb12;
      border: none;
      outline: none;
      margin-top: 20px;
      margin-right: 1rem;
    }
    .tester-details-header-make-reviewer {
      font-size: 12px;
      color: #ffffff;
      padding: 10px 23px;
      border-radius: 4px;
      background-color: #d20000;
      border: none;
      outline: none;
      margin-top: 20px;
    }
    .tester-details-header-heading {
      font-size: 1.2rem;
      font-weight: 700;
      color: #363636;
      padding-top: 15px;
    }
    .tester-details-header-devices {
      display: flex;
      padding-top: 10px;
      .noPlatform-error {
        color: #c2c2c2;
        font-size: 1rem;
        font-weight: 600;
      }
      .tester-devices-images {
        padding-right: 1.2rem;
        width: 20px;
      }
    }

    .tester-details-status-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      .tester-details-status {
        padding: 8px;
        border-radius: 50%;
        margin-right: -1.2rem;
        &.Active {
            background-color: #39b21c;
        }
        &.Inactive {
          background-color: #d20000;
        }
      }
      .tester-details-status-status {
        min-width: 50px;
      }
      .tester-details-status-change-status {
        font-size: 14px;
        color: #247dd2;
        cursor: pointer;
      }
    }
    .tester-details-buttons {
      display: flex;
      gap: 20px;
      .tester-details-header-btn {
        padding: 12px 20px;
        color: #fff;
        border: none;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        &.orange {
          background-color: #fd6529;
        }
        &.blue {
          background-color: #1c76b2;
        }
        &.red {
          background-color: #25a7a7;
        }
        &.black {
          background-color: #4fb91e;
        }
        &.yellow {
          background-color: #ddbf15;
        }
      }
    }
  }

  .tester-details-all-projects {
    margin-top: 2rem;
  }
}

@media (max-width: 786px) {
  .tester-details{
    .tester-details-header{
      .tester-details-buttons{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
      }
    }
  }
}
