@import "assets/sass/_variables.scss";
// @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

:root {
  // colors
  --glb-blue: #617af9;
  --glb-white: white;
  --glb-grey: #eee;
  --glb-dark-blue: #000f4b;
  --glb-light:#e3f2fd;
  // border
  --glb-border:1px solid #d1daeb;
  // shadows
  --glb-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  //  backgrounds
  --glb-bg-primary: white;
  --glb-bg-secondary: white;
  // texts color
  --glb-txt-primary: black;
  --glb-txt-seconday: black;
  // buttons
  --glb-btn-primary: #617af9;
  --glb-btn-general: #617af9;
  --glb-btn-secondary: white;
  // inputs
  --glb-input-primary: #eee;
  --glb-input-seconday: white;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

.glb-header {
  color: var(--glb-dark-blue);
}

.glb-input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin-bottom: 18px;
  width: 100%;
  outline: none;
}

.glb-btn {
  border-radius: 20px;
  // height: 40px;
  border: none;
  background-color: var(--glb-btn-general);
  color: var(--glb-white);
  // font-size: 12px;
  font-weight: bold;
  padding: 16px 16px;
  letter-spacing: 1px;
  // text-transform: uppercase;
  transition: transform 80ms ease-in;
  text-align: center;
  cursor: pointer;
  min-width: fit-content;
  margin: 3px;
}

.glb-btn:hover {
  transform: scale(1.1);
}

.glb-card {
  background-color: var(--glb-white);
  box-shadow: var(--glb-shadow);
  color: var(--glb-dark-blue);
}

.glb-action-btn {
  border: none;
  border-radius: 3px;
  color: var(--glb-white);
  font-size: 14px;
  font-weight: bold;
  padding: 14px 12px;
  letter-spacing: 1px;
  // text-transform: uppercase;
  transition: transform 80ms ease-in;
  text-align: center;
  cursor: pointer;
}

button {
  cursor: pointer;
  outline: none;
}

[disabled],
[disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}

body {
  // font-family: "Roboto", sans-serif;
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 16px;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.flexboxgrid {
  @import "assets/sass/_flexboxgrid.min.scss";
}
.custom-container {
  padding: 1.8rem 30px;
}
.sb {
  //show border
  border: 1px solid black;
}
.grey-bg {
  // // box-shadow: var(--glb-bg-secondary);
  background-color: #f7f7f7;
}
.white-bg {
  background-color: var(--glb-white);
}
.text-white {
  color: var(--glb-txt-primary);
}
.text-yellow {
  color: #E9901E;
}
.text-bold {
  font-weight: bold;
}
.text-grey{
  color: #5f5f5f;
}
.bg-action{
  background-color: orange;
}
.bg-light{
  background-color: #e3f2fd99;
}

.bg-highlight{
  background-color: #231651;
}
svg{
  vertical-align: middle!important;
}

.pagination-container{
  display: flex;
  justify-content: center;
  .element{
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #7e8c94;
    height: 28px;
    width: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 2rem 0.5rem;
    cursor: pointer;
  }
  .pagination {
    display: flex;
    overflow-x: scroll;
    /* width */
&::-webkit-scrollbar {
  height: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 25px;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
    .element {
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: #7e8c94;
      height: 28px;
      width: 28px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-sizing: border-box;
      margin: 2rem 0.5rem;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently*/
    }
  }
}