.addtest-step1 {
  .project-title {
    margin-bottom: 1.3rem;
    .card {
      margin-top: 0.3rem;
      .addtest-input {
        background-color: transparent;
      }
    }
  }
  .choose-platfrom {
    .card {
      margin-top: -3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 1441px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: 2rem;
      }
    }
  }
}

@media (max-width: 786px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: 2rem;
      }
    }
  }
}
@media (max-width: 752px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: 0rem;
      }
    }
  }
}
@media (max-width: 688px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -3rem;
      }
    }
  }
}
@media (max-width: 636px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -12rem;
      }
    }
  }
}
@media (max-width: 608px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -14rem;
      }
    }
  }
}
@media (max-width: 560px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -17rem;
      }
    }
  }
}
@media (max-width: 504px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -21rem;
      }
    }
  }
}
@media (max-width: 484px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -19rem;
      }
    }
  }
}
@media (max-width: 474px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -21rem;
      }
    }
  }
}
@media (max-width: 444px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -24rem;
      }
    }
  }
}
@media (max-width: 424px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -22rem;
      }
    }
  }
}
@media (max-width: 416px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -25rem;
      }
    }
  }
}
@media (max-width: 396px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -28rem;
      }
    }
  }
}
@media (max-width: 392px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -31rem;
      }
    }
  }
}
@media (max-width: 382px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -32rem;
      }
    }
  }
}
@media (max-width: 372px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -33rem;
      }
    }
  }
}
@media (max-width: 360px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -37rem;
      }
    }
  }
}
@media (max-width: 344px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -45rem;
      }
    }
  }
}
@media (max-width: 322px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -48rem;
      }
    }
  }
}
@media (max-width: 318px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -51rem;
      }
    }
  }
}
@media (max-width: 310px) {
  .addtest-step1 {
    .choose-platfrom {
      .card {
        margin-top: -54rem;
      }
    }
  }
}
