.home-automation {
  .automation-heading {
    padding-top: 90px;
    padding-bottom: 140px;
    // background: url("../../assets/images/banner-bg.png") no-repeat center center/cover;
    background-color: var(--glb-light);
    box-shadow: var(--glb-shadow);
  }
  .automation-heading-content {
    width: 1221px;
    height: 71px;
    margin: 21px auto 0 auto;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    // color: #ffffff;
  }
  .automation-heading-highlight {
    margin: 0;
    font-size: 36px;
  }
}

@media only screen and (max-width: 1440px) {
  .home-automation {
    .automation-heading {
      padding-top: 73px;
    }
  }
}
@media (max-width: 786px) {
  .home-automation {
    .automation-heading {
      padding-top: 90px;
      padding-bottom: 250px;
    }
    .automation-heading-content {
      width: 336px;
      height: 205px;
    }
  }
}
