.tester-detail-card {
  max-width: 420px;
  height: 193px;
  box-sizing: border-box;
  padding: 16px 0;
  padding-left: 0.8rem;
  border-radius: 7px;
  margin-right: 2.5rem;
  box-shadow: var(--glb-shadow);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    // flex: 2;
    height: 125px;
    width: 125px;
    background-color: #ededed;
    border-radius: 50%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .details {
    flex: 3;
    margin-left: 1rem;
    text-align: left;
    h3 {
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: var(--glb-dark-blue);
      font-weight: normal;
      padding: 0;
      margin: 0;
    }
    small {
      font-size: 9px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #515151;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0.7rem 0;
      li {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        margin: 0;
        padding: 0;
        strong {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: var(--glb-dark-blue);
        }
      }
    }
    h2 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #4d4f5c;
      margin: 0;
      padding: 0;
    }
  }
}

// @media only screen and (max-width: 1670px) {
//   .tester-detail-card{
//     .image{
//       height: 100%;
//       width: 155px;
//       background-color: #ededed;
//       border-radius: 50%;
//       img{
//         display: block;
//         width: 100%;
//         height: 100%;
//         border-radius: 50%;
//       }
//     }
//   }
// }

@media only screen and (max-width: 786px) {
  .tester-detail-card {
    width: 100%;
    margin-right: 0px;
    min-width: 310px;
    padding: 16px 0.8rem;
  }
}
