.timeline {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .timeline-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    position: relative;
    color: #ffffff;
    .step {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h4 {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #515151;
        position: absolute;
        top: 2rem;
      }
      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        // background-color: var(--glb-btn-general);
        background-color: #d2d2d2;
        color: #a4a4a4;
        z-index: 5;
        // cursor: pointer;
      }
      &:first-child {
        justify-content: flex-start;
        h4 {
          left: -35px;
        }
        .icon {
          background-color: var(--glb-btn-general);
          color: #ffffff;
        }
      }
      &:last-child {
        justify-content: flex-end;
        h4 {
          right: -20px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 150%;
        left: -100%;
        background: #d2d2d2;
        z-index: 0;
        height: 3px;
      }
      &:first-child::before {
        display: none;
      }
      &:last-child::before {
        width: 150%;
        left: -50%;
      }
      &.active {
        .icon {
          background-color: var(--glb-btn-general);
          color: #ffffff;
        }
        &::before {
          background: #123dfb;
        }
      }
      // &.active::before{
      //   background-color: var(--glb-btn-general);
      //   color: #ffffff;
      // }
    }
  }
}
