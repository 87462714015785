.counter-component {
  // background: url("../../../assets/images/footer-bg.png") no-repeat center center/cover;
  padding: 3rem 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  box-shadow: var(--glb-shadow);
  background-color: var(--glb-bg-secondary);
  .counter {
    margin: 2rem 4rem;
    color: var(--glb-white);
    .number {
      font-size: 3.3rem;
      font-weight: bold;
      // color: #ffffff;
      // color: black;
      color: inherit;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 600;
      // color: #ffcc00;
      color: inherit;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .counter-component {
    .counter {
      .number {
        font-size: 2rem;
      }
      .title {
        font-size: 1.3rem;
      }
    }
  }
}
@media (max-width: 786px) {
  .counter-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem 2rem;
    .counter {
      .number {
        font-size: 52px;
      }
      .title {
        font-size: 27px;
      }
    }
  }
}
