.login-component {
  width: 640px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 60px 100px;
  padding-top: 30px;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  border: var(--glb-border);
  margin: 1rem 0;
  text-align: center;
  .margin-top {
    margin-top: 3rem;
  }
  .login-heading {
    font-size: 1.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
    margin-bottom: 2rem;
  }
  .login-form {
    width: 100%;
    .form-group {
      position: relative;
      margin-top: 1.5rem;
      width: 100%;
      text-align: left;
      label {
        font-size: 0.7rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
        display: block;
      }
      input {
        width: 100%;
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        border: none;
        padding: 0.15rem 0 0.3rem 0;
        border-bottom: solid 1px #bcbcbc;
        &:focus {
          outline: none;
        }
      }
      input[name="password"] {
        display: inline-block;
      }
      .password-show-hide {
        position: absolute;
        right: 0%;
        cursor: pointer;
      }
    }
    .forgot-pass {
      display: block;
      text-align: left;
      font-size: 1rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #127ffb;
      text-decoration: none;
      margin: 1.3rem 0;
    }
    .submit-btn {
      display: block;
      text-align: center;
      padding: 0.9rem 1rem;
      width: 100%;
      border-radius: 4px;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      border: none;
      text-decoration: none;
      box-sizing: border-box;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .form-or {
      font-size: 1.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #505050;
    }
    .option-btn {
      display: block;
      text-align: center;
      padding: 0.9rem 1rem;
      width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #123dfb;
      border: solid 2px #123dfb;
      box-sizing: border-box;
      text-decoration: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 786px) {
  .login-component {
    width: 80%;
    padding-top: 0px;
    padding: 40px 40px 60px 40px;
  }
}
