.dropdown{
    display: block;
    margin-top: 0.1rem;
    label{
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #a4a4a4;
      margin-bottom: 7.5px;
      display: block;
      margin-top: 0.5rem;
    }
    select{
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        border: none;
        outline: none;
        border-bottom: 2px solid #a4a4a4;
        width: 320px;
        color: #505050;
        background-color: transparent;
        display: block;
        .option{
            font-size: 20px;
        }
    }
}

@media  screen and (max-width: 786px){
    .dropdown{
        select {
            width: 100%;
        }
    }
}