.adminHome {
  .cardContainer {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1100px;
  }
}

@media (max-width: 786px) {
  .adminHome {
    .cardContainer {
      justify-content: center;
    }
  }
}