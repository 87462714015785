.none {
  display: none;
}

.homescreen-modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 9999;
  .homescreen-modal {
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2rem 1.9rem;
    box-sizing: border-box;
    min-width: 700px;
    .close-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .close {
        width: max-content;
        cursor: pointer;
      }
    }
    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.6rem;
      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
        margin: 0;
        padding-bottom: 18px;
      }
      h4 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: #393939;
      }
    }

    .body-container {
      padding-bottom: 1.5rem;
      .body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        background: linear-gradient(#b2b2b2, #b2b2b2) center/2px 100% no-repeat;

        .client,
        .tester {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .section-heading {
            font-size: 1.2rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            letter-spacing: normal;
            text-align: center;
            color: var(--glb-dark-blue);
            padding-bottom: 1.5rem;
          }

          .section-image {
            img {
              height: 200px;
              width: 200px;
            }
          }

          .section-button {
            padding-top: 1.5rem;
            button {
              padding: 14px 38px;
              border-radius: 4px;
              background-color: var(--glb-btn-general);
              outline: none;
              border: none;
              font-size: 0.8rem;
              font-weight: 600;
              line-height: 1.21;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .homescreen-modal-container {
    position: fixed;
    width: 95%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 20%;
    overflow-y: scroll;
    //padding: 150px 0px;
    .homescreen-modal {
      min-width: 300px;
      height: auto;
      width: 99%;
      margin-top: 35vh;
      margin-bottom: 5vh;
      padding: 15px 15px 15px 15px;
      border-radius: 25px;
      .body-container {
        .body {
          grid-template-columns: 1fr;
          gap: 2rem;
          background: none;
        }
      }
    }
  }
}
