.component1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
  padding-top: 70px;
  .component1-heading {
    margin: 1px auto 1px auto;
    width: 698px;
    height: 87px;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
    .component1-highlight {
      font-weight: bold;
    }
  }
  .component1-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 100px;
    padding-top: 65px;
    .component1-box {
      text-align: center;
      color: #5a5a5a;
      .component1-box-heading {
        font-size: 30px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
        margin: 0;
        margin-top: 16px;
        margin-bottom: 11px;
      }
    }
  }
}
@media (max-width: 786px) {
  .component1 {
    .component1-heading {
      width: 350px;
      margin-bottom: 40px;
    }
    .component1-icons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
      column-gap: 20px;
    }
  }
}
