.usertesting {
  width: 100%;
  min-height: 270px;
  box-shadow: var(--glb-shadow);
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 19px 39.3px 19px 29px;
    background-color: #4056d8;
    border-radius: 10px 10px 0 0;
    &-left-textstyle-2,
    &-left-textstyle-1 {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
    &-left-textstyle-2 {
      font-weight: bold;
    }
  }
}

.leaderboard-table {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  &-1 {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 10px;
    margin: auto;
  }

  &-heading {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #515151;
    padding: 10px;
  }
}

.earnings {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #4d4f5c;
}

.Bhupendra-Yadapalli {
  width: 170px;
  height: 19px;
  margin: 18px 230px 11.5px 9px;
  padding: 10px 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: var(--glb-dark-blue);
  text-transform: capitalize;
}

.Bhupendra-Yadapalli .text-style-1 {
  font-weight: 600;
}
