.testimonial {
  .testimonial-summary {
    margin-top: 20px;
    width: 750px;
    height: 250px;
    outline: none;
    background-color: var(--glb-white);
    border: none;
    border-radius: 15px;
    padding: 1rem;
    line-height: 1.5;
    box-shadow: var(--glb-shadow);
  }
  button {
    display: block;
    border: none;
    outline: none;
    width: 235px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem 3rem;
    border-color: #123dfb;
    background-color: var(--glb-btn-general);
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color:var(--glb-white);
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 786px) {
  .testimonial {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .testimonial-summary {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
