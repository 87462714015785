.dashboard-heading-container {
  margin-bottom: 1rem;
  width: 100%;
  .dashboard-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .dashboard-heading-inner {
      display: flex;
      align-items: center;
      h2 {
        font-size: 1.6rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        text-align: left;
        color: #233137;
        font-weight: normal;
        padding: 0;
        margin: 0;
      }
      button {
        display: block;
        border: none;
        margin-left: 30px;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        border-radius: 7px;
        color: #ffffff;
        background-color: var(--glb-btn-general);
        cursor: pointer;
        &.l {
          padding: 0.7rem 1.6rem;
        }
        &.sec-btn {
          border: solid 2px #008716;
          background-color: transparent;
          color: #0a9a00;
          &:hover {
            color: #ffffff;
            background-color: #008716;
          }
        }
        &:hover {
          background-color: var(--glb-btn-general);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .dashboard-heading-container {
    .dashboard-heading {
      flex-wrap: wrap-reverse;
      .dashboard-heading-inner {
        h2 {
          font-size: 22px;
          line-height: 1.32;
        }
        button {
          &.sec-btn {
            margin: 0px;
            width: 180px;
            font-size: 17px;
            line-height: 1.35;
            text-align: center;
            margin-bottom: 24px;
          }
          font-size: 14px;
        }
      }
    }
  }
}
