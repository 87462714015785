.tester_newinfo {
  background-color: #fff;
  padding-top: 50px;
  margin: 0 auto;
  padding-bottom: 100px;
  .dark {
    font-weight: bold;
  }
  .tester_new_info_heading {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .tester_newinfocontent {
    width: 90%;
    margin: 30px auto 62px auto;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #5a5a5a;
  }
  .tester_icons_and_description {
    margin: 1px auto;
    width: 100%;
    text-align: center;
    .howItWorks{
      display: flex;
      justify-content: space-around;
      .howItWorks-item{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h4{
          margin: 30px 30px;
        }
        p{
          width: 60%;
          margin: auto;
          text-align: center;
        }
      }
    }
    .testerGetStarted{
      max-width: 300px;
      margin: 20px auto 0px;
    }
  }
  .tester_icons_and_description_img {
    display: none;
  }
  .tester_icons_placement {
    margin: 1px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .tester_styled_border {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    margin-top: 23px;
    margin-bottom: 15px;
    .tester_first_circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .tester_first_empty_div {
      width: 32%;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .tester_second_circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .tester_second_empty_div {
      width: 32%;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .tester_third_circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
  }
  .tester_icons_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .tester_content_first {
      width: 300px;
      height: 44px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .tester_content_second {
      width: 330px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .tester_content_third {
      width: 314px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .tester_newinfo {
    .tester_icons_placement {
      margin: 1px auto;
      width: 100%;
    }
  }
}

@media screen and (max-width: 786px) {
  .tester_newinfo {
    .tester_icons_and_description {
      .howItWorks{
        flex-wrap: wrap;
        .howItWorks-item{
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .tester_icons_and_description_img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
