.requestTransferModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  .card {
    width: 40%;
    background: white;
    padding: 2rem 1.9rem;
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    h2 {
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: var(--glb-dark-blue);
      margin: 0;
      padding-bottom: 18px;
    }
    .closeTransferModal {
      position: relative;
      margin: 0px;
      float: right;
      cursor: pointer;
    }
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          text-align: center;
          font-weight: 400;
          margin-bottom: 0.5rem;
          th {
            width: fit-content;
            &:first-child {
              text-align: left;
            }
          }
        }
      }

      tbody {
        margin-top: 2rem;
        tr {
          text-align: center;
          font-weight: 400;
          margin-bottom: 0.5rem;
          td {
            width: fit-content;
            &:first-child {
              color: navy;
              text-align: left;
              font-weight: 500px;
            }
            button {
              background-color: navy;
              color: var(--glb-white);
              border: none;
              outline: none;
              padding: 0.25rem 0.5rem;
              border-radius: 3px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
