.all-test-taken {
  .BackBtnContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &.FormFillUp-backbtn {
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #123dfb;
      background-color: transparent;
      margin-bottom: 10px;
      cursor: pointer;
      width: max-content;
    }
  }
  .test-taken-container {
    box-shadow: var(--glb-shadow);
    border-radius: 10px;
    max-width: 1350px;
    margin: 2rem 0rem;
    .test-taken {
      box-sizing: border-box;
      background-color: #ffffff;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      padding: 1.5rem 0.5rem;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .label {
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          color: #a4a4a4;
          margin-bottom: 0.6rem;
        }
        .show-details {
          box-sizing: border-box;
          border-bottom: solid 1px #bcbcbc;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #505050;
          padding: 0.5rem 1rem;
          &.webLink {
            color: #006ea5;
            // width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
        .show-btn {
          margin-bottom: 0.5rem;
          box-sizing: border-box;
          button {
            width: 185px;
            height: 40px;
            background-color: #007a04;
            border-radius: 4px;
            border: none;
            outline: none;
            padding: 0.5rem 1rem;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .test-taken-description-container {
      box-sizing: border-box;
      margin-bottom: 2rem;
      background-color: #ffffff;
      padding: 0.5rem 2rem;
      .description-label {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
        margin-bottom: 0.6rem;
      }
      .test-taken-description {
        border: 1px solid #bcbcbc;
        padding: 1rem;
        margin-bottom: 1rem;
        .test-taken-description-details {
          box-sizing: border-box;
          font-size: 16px;
          margin: 0;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: 10px;
          color: #505050;
        }
        button {
          border: none;
          outline: none;
          padding: 4px 5px 4px 6px;
          border-radius: 4px;
          background-color: transparent;
          font-size: 19px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: black;
          cursor: pointer;
        }
      }
    }
  }

  .download-application-btn {
    text-align: right;
    button {
      border: none;
      outline: none;
      padding: 14px 18px 14px 24px;
      border-radius: 4px;
      background-color: var(--glb-btn-general);
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 39px;
      cursor: pointer;
    }
  }
  .upload-results {
    &-heading {
      display: flex;
      max-width: 1350px;
      gap: 20px;
      .report-bug-btn {
        width: 196px;
        height: 44px;
        background-color: #008716;
        outline: none;
        border: none;
        border-radius: 10px;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .all-test-taken {
    .test-taken {
      table {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .all-test-taken {
    margin-top: 2rem;
    width: 100%;
    .test-taken {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 15px;
      row-gap: 15px;
      .item {
        .show-btn {
          button {
            width: 128px;
          }
        }
        .label {
          font-size: 10px;
        }
        .show-details {
          font-size: 12px;
          // &.webLink {
          //   width: 350px;
          // }
        }
      }
    }
    .download-application-btn {
      display: flex;
      justify-content: center;
      button {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
      }
    }
    .upload-results {
      font-size: 12px;
      &-heading {
        width: 100%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .all-test-taken {
    .test-taken {
      grid-template-columns: 1fr;
      .item {
        .show-details {
          font-size: 12px;
          // &.webLink {
          //   width: 250px;
          // }
        }
      }
    }
  }
}
