.community-leaderboard-heading{
  margin-top: 0.8rem;
  margin-bottom: 1.45rem;
}
.Our-Community-Leaderboard{
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #233137;
  margin-bottom:40px;
}

.text-style-1{
  font-weight: normal;
}
.leaderboard-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  max-width: 1190px;
  .leaderboard-stats-color-tiles{
    display:grid;
    width: 100% ;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    .leaderboard-stats-color-tiles-card{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius:10px;
      width: 100%;
      box-sizing: border-box;
      // &::before{
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   opacity: 0.3;
      //   background: url("../../../assets/images/CommunityCover.png") no-repeat center center/cover;
      //   z-index: 1;
      // }
      &.bg1{
        background: url("../../../assets/images/Community1.png") no-repeat center center/cover;
      }
      &.bg2{
        background: url("../../../assets/images/Community2.png") no-repeat center center/cover;
      }
      &.bg3{
        background: url("../../../assets/images/Community3.png") no-repeat center center/cover;
      }
      &.bg4{
        background: url("../../../assets/images/Community4.png") no-repeat center center/cover;
      }
      .background{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.3;
        background: url("../../../assets/images/CommunityCover.png") no-repeat center center/cover;
        z-index: 1;
      }
      .content{
        z-index: 5;
        .title{
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin: 0;
          margin-top: 1.2rem;
        }
        .value{
          line-height: 1.22;
          font-size: 63px;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin: 0;
          margin-bottom: 1.2rem;
        }
      }
    }       
  }
}
@media  screen and (max-width: 786px){
  .leaderboard-stats{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
