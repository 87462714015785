.manageTestimonials{
    .approvedTestimonials{
        display: flex;
        gap: 60px;
        flex-wrap: wrap;
        margin-bottom: 50px;
        h2{
            margin-bottom: 0;
        }

    }
    .pendingTestimonials{
        display: flex;
        gap: 60px;
        flex-wrap: wrap;
        margin-bottom: 50px;
        h2{
            margin-bottom: 0;
        }
    }
    .rejectedTestimonials{
        display: flex;
        gap: 60px;
        flex-wrap: wrap;
        h2{
            margin-bottom: 0;
        }
    }
}