.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .pricing-card {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 1.5rem;
    box-shadow: var(--glb-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 280px;
    .pricing-card-heading {
      font-size: 1.2rem;
      color: var(--glb-dark-blue);
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    .pricing-card-subHeading {
      font-size: 1rem;
      color: var(--glb-dark-blue);
      font-weight: 500;
    }
    .pricing-card-flex {
      width: 100%;
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .pricing-card-flex-heading {
        width: 100%;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--glb-dark-blue);
        font-size: 1rem;
        font-weight: 700;
      }
      .pricing-card-flex-row {
        width: 100%;
        padding: 0.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .devices {
          color: #000;
          font-size: 0.8rem;
          font-weight: 500;
        }
        .price {
          color: #000;
          font-size: 0.8rem;
          font-weight: 500;
        }
      }
    }
  }
}
