.dropdownItems {
	position: absolute;
	top:calc(100% + 0.5rem);
	list-style: none;
	text-align: start;
    background-color: #fff;
	visibility: hidden;
	opacity: 0;
	width: 282px;
	transition: all 0.5s ease;
    box-shadow: var(--glb-shadow);
	li {
		cursor: pointer;
		&:hover {
			background: var(--glb-blue);
            color: var(--glb-white)!important;
		}
	}
}
.dropdown-menu.clicked {
	display: none;
}
.dropdown-link {
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
	color: #fff;
	padding: 16px;
    &:hover{
        color: #fff!important; 
    }
}
@media screen and (max-width: 1200px) {
	.dropdownItems {
		position: relative;
		text-align: center;
		background-color: inherit;
		visibility: visible;
		opacity: 1;
		width: 100%;
		box-shadow: none;
	}
	.dropdown-link {
		padding: 0px;
		color: #000;
		&:hover{
			color: #000!important; 
		}
	}
}

@media screen and (max-width: 960px) {
	.fa-caret-down {
		display: none;
	}
}

