.none {
  display: none;
}
.modal-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  z-index: 9999;
  .modal {
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2rem 1.9rem;
    box-sizing: border-box;
    min-width: 700px;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.6rem;
      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        text-align: left;
        color: #233137;
      }
      .close-icon {
        text-align: left;
        cursor: pointer;
        svg {
          height: 23px;
          fill: #ababab;
        }
        &:hover {
          svg {
            fill: #8b8b8b;
          }
        }
      }
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 650px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .modal-container {
    // padding-top: 80px;
    width: 100vw;
    .modal {
      padding-top: 1.5rem;
      margin-bottom: 1rem;
      .heading {
        h2 {
          font-size: 1.3rem;
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: #e4e4e4;
        }
        &::-webkit-scrollbar-thumb {
          background: #a2a2a2;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #929292;
        }
      }
      .body {
        overflow-x: auto;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 600px;
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          background: #e4e4e4;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #a2a2a2;
        }
        &::-webkit-scrollbar-thumb:hover {
          border-radius: 5px;
          background: #929292;
        }
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .modal-container {
    width: 95%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    .modal {
      min-width: 250px;

      width: 99%;
      .body {
        // padding: 500px 0px;
        // min-width: 300px;
        width: 99%;
        .device-select-container {
          .next-step-btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .modal-container {
    .modal {
      padding: 2rem 1rem;
    }
  }
}
