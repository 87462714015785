.multiplier-card {
  width: 100%;
  height: 468px;
  /*margin: 28px 40px 34px 0;*/
  padding: 50px 30px;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .pointer {
    cursor: pointer;
  }
  .heading {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center;
    color: #565656;
    padding-bottom: 20px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-bottom: 20px;
  }
  .list {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #565656;
    padding-bottom: 20px;
    ul {
      margin: 0.5rem 0;
    }
  }
  button {
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    background-color: #008080;
    border: none;
    outline: none;
    font-size: 1.1rem;
    padding: 0.6rem 2.5rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}

@media screen and (max-width: 786px) {
  .multiplier-card {
    width: 235px;
  }
}
