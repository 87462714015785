.FormFillUp{

    .attach-file{
        .label{
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            letter-spacing: normal;
            text-align: left;
            color: #606060;          
        }
        label{
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            width: 502px;
            box-sizing: border-box;
            border-radius: 4px;
            border: solid 1px #cfcfcf;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #008080;
            cursor: pointer;
        }
        input{
            visibility: hidden;
            height: 0px;
            width: 0px;
        }
    }
  margin-right: 39px;
  &-subheading{
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #606060;
      &-textstyle1{
          font-size: 21px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #606060;
          font-weight: bold;
      }
  }
  &-backbtn{
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #123dfb;
      background-color: transparent;
      margin-bottom: 10px;
      cursor: pointer;
  }
  &-fileupload-btn{
      width:502px;
      height: 41px;
      border-radius: 4px;
      border: dashed 1px #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #a5a5a5;
      margin-bottom: 30px;
      cursor:pointer;
  }
  &-submitbtn{
      height: 51px;
      padding: 14px 84px;
      border-radius: 4px;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-right: 1rem;
      outline: none;
      border: none;
      cursor:pointer;
      margin-top: 1.5rem;
      &.green {
          background-color: #008716;
      }
      &.red {
          background-color: #ff0000;
      }
  }
  
    .btn-save-draft{
        margin-top: 1.5rem;
        height: 51px;
        padding: 14px 50px;
        border-radius: 4px;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: #008716;
        outline: none;
        border: 2px solid #008716;
        margin-right: 1rem;
        cursor:pointer;
    }
}
.inputbox{
    &-wrapper{
        margin-bottom:30px;
    }
  &-heading{
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #606060;
      margin-bottom: 10px;
  }
  display: block;
  width: 835px;
  height: 41px;
  padding: 13px ;
  border-radius: 4px;
  border: solid 1px #cfcfcf;
  background-color: #f3f3f3;
  &:focus{
      outline: none;
  }

}

.dropbox{
    &:focus{
        outline: none;
    }
  &-heading{
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #606060;
      margin-bottom: 5px;
  }
  &-wrapper{
      margin-bottom:30px;
  }
  .dropbox-option{
      text-align:center;
  }
  width: 502px;
  height: 41px;
  border-radius: 4px;
  border: solid 1px #cfcfcf;
  background-color: #f3f3f3;
  text-align: left;
}
.FormFillUp-textarea{
  height:188px;
  width:835px;
  border-radius: 4px;
  border: solid 1px #cfcfcf;
  background-color: #f3f3f3;
  text-align:left;
  line-height:20px ;
  &:focus{
    outline: none;
  }
}


@media screen and (max-width: 786px) {
    .FormFillUp{
        margin-right: 0px;
        .inputbox-wrapper {
            width: 95%;
            .inputbox {
                width: 100%;
                padding: 10px;
            }
        }
        .dropbox-wrapper {
            width: 100%;
            .dropbox {
                width: 100%;
            }
        }
        .attach-file {
            width: 100%;
            label {
                width: 100%;
            }
        }

        .FormFillUp-textarea {
            width: 100%;
        }

        .FormFillUp-submitbtn {
            width: 100%;
        }

    }
}