.EarningsmainAccordian {
  width: 100%;
  max-width: 950px;
  .earnings-list-item {
    border-radius: 4px;
    border: solid 1px #d4dfff;
    background-color: #ffffff;
    box-shadow: var(--glb-shadow);
    padding: 10px 18px 9px 21px;
    margin-bottom: 30px;
    .earnings-list-item-title {
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        margin: 5px 0px;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
      }
      .myearnings-project-header {
        display: flex;
        align-items: center;
        gap: 5rem;
        span {
          background-color: #d4dfff;
          border-radius: 50%;
          width: 36px;
          height: 36px;
        }
      }
    }
    .accordianContent {
      .row {
        display: flex;
        padding: 10px 0px;
        table {
          tr {
            td {
              &.APPROVED {
                color: #0a9a00;
              }
              &.PENDING {
                color: #ff7001;
              }
              &.REJECTED {
                color: #b20000;
              }
              &.bugName {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.33;
              }
            }
          }
        }
      }
    }
  }
}
