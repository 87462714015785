.BadgeCard {
  position: relative;
  background-color: #fff;
  flex-grow: 1;
  padding: 20px;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  display: flex;
  width: 27%;
  max-width: 27%;
  overflow: hidden;
  gap: 20px;
  border: var(--glb-border);
  .badgeOptions {
    position: absolute;
    top: 5%;
    left: auto;
    right: 5%;
    .deleteBadge {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        color: #fb3a3a;
        vertical-align: middle;
      }
    }
    .editBadge {
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
  }
  .cardHeader {
    display: flex;
    align-items: center;
  }
  .cardfooter {
    overflow: hidden;
    .cardContent {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .cardHeading {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: #8898aa;
        padding-bottom: 7px;
        text-overflow: ellipsis;
      }
      .cardNumber {
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #4d4f5c;
      }
      .cardDescription {
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .BadgeCard {
    width: 100%;
    max-width: 100%;
  }
}
