.dashboard-client-new-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #233137;
    font-weight: normal;
    padding: 0;
    margin: 0;
    strong {
      font-weight: bold;
    }
  }
  .subHead {
    padding: 20px 0px;
  }

  .select-platform-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: 30px;
  }

  .sub-bold-heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.32;
    color: #233137;
    padding-bottom: 0.8rem;
  }

  .client-add-test-para {
    max-width: 600px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    text-align: center;
    color: #505050;
  }

  .client-new-test-button {
    padding: 14px 42px;
    border-radius: 4px;
    background-color: var(--glb-btn-general);
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1.23;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    margin: 20px;
  }
}

@media (max-width: 786px) {
  .select-platform-card-container {
    flex-direction: column;
  }
}
