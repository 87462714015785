.account-setup-heading {
  padding-top: 1.5rem;
  // color: #ffffff;
  text-align: center;
  .heading {
    font-size: 3rem;
    margin: 0;
    margin-bottom: 1rem;
  }
  .description {
    font-size: 26px;
    margin: 0;
    .highlight {
      font-weight: bold;
      color: #ffcc00;
    }
  }
}

@media screen and (max-width: 786px) {
  .account-setup-heading {
    .heading {
      font-size: 2rem;
      padding: 0px 2rem;
    }
    .description {
      font-size: 1rem;
      padding: 0px 1rem;
    }
  }
}
