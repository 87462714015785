.device-details-modal{
  width: 95%;
  .selected-platform{
    font-size: 1.1rem;
    color: #999999;
    margin-bottom: 1rem;
  }
  .form-group{
    border-bottom: solid 1px #bcbcbc;
    padding-bottom: 0.3rem;
    margin-bottom: 0.7rem;
    width: 100%;
    label{
      display: block;
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #a4a4a4;
      margin-bottom: 0.3rem;
    }
    input{
      font-size: 1.2rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #505050;
      border: none;
      width: 100%;
      &::-webkit-input-placeholder {
        font-size:  1rem;
        line-height: 2;
      }
      &:focus{
        outline: none;
      }
    }
  }
  .form-submit{
    
    
    button{
      border: none;
      font-size: 1.1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;    
      border-radius: 4px;
      background: #008f21;
      color: #ffffff;
      padding: 0.8rem 2.3rem;
      cursor: pointer;
      &:hover{
        background: #09681f;
      }
      &:focus{
        outline: none;
      }
    }
  }
}

.device-select-container{
  position: relative;
  .next-step-btn{
    border: none;
    padding: 0.8rem;
    width: 100%;
    cursor: pointer;
    background-color: #008f21;
    text-align: center;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 600;
    
    &.disabled{
      background-color: #bcbcbc; 
    }
    &:focus{
      outline: none;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .device-select-container{
    position: relative;
    top: 120px;
  }
  .device-details-modal{
    .form-group{
      input{
        font-size: 0.9rem;
      }
    }
    .form-submit{
      margin-top: 1.5rem;
    }
  }
}

@media  screen and (max-width: 786px){
  .device-details-modal{
    .form-group {
      width: 90%;
    }
  }
}