.commentModal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    .commentModalContainer{
        width: 60%;
        height: 70%;
        padding: 20px;
        background-color: white;
        box-shadow: var(--glb-shadow);
        border: var(--glb-border);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 10px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          //box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.3); 
          border-radius: 10px;
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(204, 204, 204, 0.5); 
          border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #bbbbbb99; 
        }
       h3{
           display: inline-block;
       }
.allComments{
    max-height: 350px;
    overflow-y: scroll;
        /* width */
&::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.3); 
    border-radius: 10px;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.5); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb99; 
  }
    .singleComment{
        box-shadow: var(--glb-shadow);
        border: var(--glb-border);
        padding: 0.5rem;
        margin-top: 20px;
        margin-bottom: 20px;
        .commentMessage {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .commenterName{
            display: block;
            text-align: right;
            font-style: italic;
            // &::before{
            //     content: "~ ";
            // }
        }
    }
}
        textarea{
            width: 100%;
            border: 1px solid #ccc;
            background: #FFF;
            margin: 0 0 5px;
            padding: 10px;
            height: 100px;
            &::placeholder{
              color: #aaa;
            }
            &:focus{
              outline: #1f1f1f;
            }
            }
         
        button{
            float: right;
            padding-left: 1rem;
            padding-right: 1rem;
        }    
    }
}

