.new-info-01 {
  padding-top: 70px;
  padding-bottom: 100px;
  box-shadow: var(--glb-bg-secondary);
  .new-info-01-heading {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .new-info-01-content {
    width: 100%;
    margin: 30px auto 43px auto;
    font-size: 22px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    color: #5a5a5a;
  }
  .new-info-01-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 125px;
    margin: 50px;
    margin-top: 66px;
    .new-info-01-img {
      background: url("../../../assets/images/10172.png") no-repeat center center/cover;
      display: block;
      width: 550px;
      height: 410px;
      // background-size: cover;
      // background-repeat: no-repeat;
    }
    .new-info-01-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: 115px;
      row-gap: 45px;
      .new-info-icon {
        text-align: center;
      }
    }
    .new-info-01-btn {
      display: block;
      width: 234px;
      // height: 50px;
      margin: 45px 0px 0px 40px;
      // padding: 25px 0px 0px 0px;
      // text-align: center;
      // color: var(--glb-txt-primary);
      // font-size: 19px;
      // font-weight: 600;
      // border-radius: 4px;
      // background-color: var(--glb-btn-general);
      // cursor: pointer;
    }
  }
}
@media (max-width: 1250px) {
  .new-info-01 {
    .new-info-01-flex {
      .new-info-01-img {
        display: none;
      }
      .new-info-01-features {
        .new-info-01-btn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .new-info-01 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .new-info-01-content {
      width: 60%;
      margin-top: 0%;
      margin-bottom: 0%;
      text-align: center;
    }
    .new-info-01-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .new-info-01-img {
        display: none;
      }
      .new-info-01-grid {
        column-gap: 20px;
      }
    }
    .new-info-01-features {
      .new-info-01-btn {
        display: none;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
