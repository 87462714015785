.recover-password {
  h1 {
    margin: 0;
    font-size: 1.9rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
  }
  .form-inputs {
    display: block;
    // margin: 2rem 0;
    .form-group {
      margin: 2rem 0;
      label {
        display: block;
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
      }
      input {
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-bottom: solid 1px #bcbcbc;
        &:focus {
          outline: none;
        }
      }
    }
    .submit-btn {
      border: none;
      width: 100%;
      font-size: 1.05rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 0.9rem;
      border-radius: 4px;
      cursor: pointer;
      background-color: var(--glb-btn-general);
    }
  }
}
@media only screen and (max-width: 1400px) {
  .recover-password {
    h1 {
      font-size: 1.4rem;
    }
    .form-inputs {
      display: block;
      .form-group {
        margin: 1.7rem 0;
        label {
          font-size: 0.7rem;
        }
        input {
          font-size: 1rem;
        }
      }
      .submit-btn {
        font-size: 1rem;
      }
    }
  }
}
