.none {
  display: none;
}
.add-skill-popup-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  .add-skill-popup {
    width: 409px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 1.5rem;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem;
      padding-bottom: 1.5rem;
      border-bottom: solid 1px #d8d8d8;
      .text {
        font-size: 1.1rem;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
      }
      .close-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        svg {
          fill: #cbcbcb;
          height: 22px;
          width: 22px;
          transition: all 0.3s;
        }
        &:hover {
          svg {
            fill: #9b9b9b;
          }
        }
      }
    }
    .stars {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      margin: 1rem 0;
      margin-top: 1.7rem;
      .single-star {
        cursor: pointer;
        svg {
          width: 36px;
          height: 36px;
          fill: #dedede;
          transition: all 0.1s;
        }
        &.selected {
          svg {
            fill: #ffc107;
          }
        }
        &:hover {
          svg {
            fill: #ffc107;
          }
        }
      }
    }
    .submit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 0.7rem;
      width: 100%;
      button {
        border-radius: 4px;
        background-color: #008f21;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 1rem;
        width: 70%;
        border: none;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          background-color: #136b28;
        }
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .add-skill-popup-container {
    width: 90vw;
    .add-skill-popup {
      width: 90%;
      padding: 1rem;
    }
  }
}
