.dashboard-client-addtest {
  display: flex;
  max-width: 1300px;
  box-sizing: border-box;
  padding-right: 5rem;
  padding-left: 1rem;
  .addtest-steps {
    flex: 2;
    box-sizing: border-box;
    max-width: 650px;
    margin-right: 3rem;
  }
  .addtest-cart-container {
    flex: 2;
    margin-left: 3rem;
    padding-right: 1rem;
    box-sizing: border-box;
    max-width: 441px;
    button {
      width: 100%;
      font-size: 1.15rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      background-color: var(--glb-btn-general);
      border-radius: 4px;
      padding: 0.7rem 1rem;
      box-sizing: border-box;
      border: none;
      margin-top: 2.5rem;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}

.addtest-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: #505050;
  border: none;
  border-bottom: 1px solid #bcbcbc;
  padding: 0.5rem 0;
  &:focus {
    outline: none;
  }
}

.back-btn {
  font-size: 1.1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #123dfb;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  .dashboard-client-addtest {
    width: 100%;

    .addtest-steps {
      margin-left: 1rem;
    }
    .addtest-cart-container {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
@media screen and (max-width: 900px) {
  .dashboard-client-addtest {
    flex-direction: column;
  }
}

@media screen and (max-width: 786px) {
  .dashboard-client-addtest {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    .addtest-steps {
      width: 100%;
      margin: auto 0px;
    }
    .addtest-cart-container {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
