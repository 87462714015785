.tester-signup-main-container {
  padding-top: 82px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background-color: var(--glb-bg-secondary);
  display: flex;
  .tester-signup-image-section {
    background-image: url("../../assets/images/bg-1.png");
    background-size: 100% 100%;
    width: 40%;
    display: flex;
    align-items: center;
    .tester-signup-image-container {
      width: 90%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .tester-signup-content-section {
    width: 60%;
    padding: 40px 60px;
    .tester-signup-content-heading {
      font-size: 30px;
      font-weight: 600;
      color: #0333f3;
      margin-bottom: 40px;
    }
    .social-logins {
      display: flex;
      align-items: center;
      gap: 5%;
      margin-top: 60px;
      margin-bottom: 50px;
      .LinkedIn {
        background-color: transparent;
        width: 45%;
        height: 45px;
        text-align: center;
        padding: 10px;
        outline: none;
        border: 1px solid #0333f3;
        border-radius: 10px;
        color: #1f1f1f;
        box-shadow: var(--glb-shadow);
        cursor: pointer;
      }
      .Google {
        background-color: transparent;
        width: 45%;
        height: 45px;
        text-align: center;
        padding: 10px;
        outline: none;
        border: 1px solid #0333f3;
        border-radius: 10px;
        color: #1f1f1f;
        box-shadow: var(--glb-shadow);
        cursor: pointer;
      }
    }
    .progress-bar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }
    .tester-details-form {
      display: flex;
      flex-wrap: wrap;
      .details-input-multifields {
        display: flex;
        width: 45%;
        margin: 0 auto 40px 0;
      }
      .details-input-fields {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto 20px 0;
        width: 45%;
        label,
        .label {
          font-size: 12px;
          font-weight: 500;
          color: #a4a4a4;
        }
        input[name="passwordRep"] {
          display: inline-block;
        }
        .password-show-hide {
          position: absolute;
          top: 40%;
          right: 0%;
          cursor: pointer;
        }
        label.user-image-label {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.6rem;
          color: #008080;
          box-sizing: border-box;
          font-size: 0.8rem;
          border: 1px dashed #cfcfcf;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        input,
        select {
          // border: none;
          border: 1px solid #0333f3;
          border-radius: 10px;
          margin-top: 5px;
          font-size: 20px;
          padding: 10px 5px;
          color: #4b4b4b;
          background-color: var(--glb-bg-secondary);
          &::placeholder {
            font-size: 12px;
          }
          &:focus {
            outline: none;
            border-bottom: 1px solid #0084dc;
          }
          &::placeholder,
          &::-ms-input-placeholder,
          &:-ms-input-placeholder {
            color: #a4a4a4;
          }
        }
        input.image-input {
          visibility: hidden;
          padding: 0;
          width: 0px;
          height: 0px;
          cursor: pointer;
        }
        .hide {
          visibility: hidden;
          font-size: 10px;
          padding-top: 0.3rem;
        }
        .loading-data {
          color: #a4a4a4;
          font-size: 10px;
          padding-top: 0.3rem;
        }
        .show-error {
          color: #fc3232;
          font-size: 10px;
          padding-top: 0.3rem;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
    .tester-signup-domain-map {
      margin-bottom: 40px;
      .back-btn {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;
        font-size: 1.1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #123dfb;
        cursor: pointer;
        img {
          margin-right: 0.2rem;
        }
      }
      .domain-title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        span {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #a4a4a4;
        }
      }
      .domains {
        display: flex;
        flex-wrap: wrap;
        .domain-name {
          border: solid 1px #cbcbcb;
          border-radius: 4px;
          padding: 10px;
          width: max-content;
          cursor: pointer;
          color: #565656;
          font-size: 12px;
          font-weight: 500;
          margin: 0 15px 15px 0;
          &.active {
            background-color: #0084dc;
            color: var(--glb-txt-primary);
          }
        }
      }
    }
    .user-consents {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #565656;
        margin-bottom: 20px;
      }
      input {
        cursor: pointer;
      }
    }
    .prev-next {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .submit-form {
        width: max-content;
        padding: 14px 70px 14px 70px;
        border-radius: 4px;
        border: none;
        background-color: var(--glb-btn-general);
        color: var(--glb-white);
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    #prev-next-1 {
      justify-content: flex-start;
    }
    .welcome-heading {
      width: 90%;
      padding: 0 1rem;
      font-size: 30px;
      font-weight: 600;
      color: #0333f3;
    }
    .signup-success {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 35px;
    }
  }
}
@media only screen and (max-width: 1440px) {
  .tester-signup-main-container {
    padding-top: 73px;
    .tester-signup-content-section {
      padding: 20px 60px;
    }
  }
}
@media (max-width: 786px) {
  .tester-signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .tester-signup-image-section {
      width: 100%;
      height: 450px;
    }
    .tester-signup-content-section {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-right: 0px;
      padding-left: 0px;
      .tester-signup-content-heading {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .progress-bar {
        img {
          width: 80%;
        }
      }
      .social-logins {
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        .LinkedIn {
          width: 80%;
        }
        .Google {
          width: 80%;
        }
      }
      .tester-details-form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        .details-input-fields {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
        .details-input-multifields {
          gap: 20px;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          .details-input-fields {
            width: 50%;
          }
        }
      }
      .user-consents {
        width: 80%;
        margin-top: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        label {
          text-align: center;
        }
      }
      .prev-next {
        justify-content: center;
        .submit-form {
          width: 206px;
          display: flex;
          justify-content: center;
          // margin-left: auto;
          // margin-right: auto;
        }
      }
    }
  }
}
