#badgeEventCard{
    justify-content: space-around;
    gap:20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 100%;
  .eventItems{
      margin-top: 30px;
    .event-card-title{
        color: #aaa;
    }
    .eventName{
        margin-top: 0;
        text-transform: uppercase;
    }
    .eventScore{
        margin-top: 0;   
    }
}
}

@media screen and (max-width:767px){
    #badgeEventCard{
        .event-card-title,.eventName,.eventScore{
            font-size: 15px;
        }
    }
}
@media screen and (max-width:400px){
    #badgeEventCard{
        .event-card-title,.eventName,.eventScore{
            font-size: 13px;
        }
    }
}
@media screen and (max-width:350px){
    #badgeEventCard{
        .event-card-title,.eventName,.eventScore{
            font-size: 11px;
        }
    }
}