.client-dash-settings {
  .tab-buttons {
    flex-direction: row;
    margin-bottom: 25px;
    .view-profile {
      width: 150px;
      outline: none;
      border: none;
      box-shadow: var(--glb-shadow);
      border-radius: 3px;
      padding: 5px 0px;
      cursor: pointer;
    }
    .change-password {
      width: 150px;
      outline: none;
      border: none;
      box-shadow: var(--glb-shadow);
      border-radius: 3px;
      padding: 5px 0px;
      cursor: pointer;
    }
    .add-bank-details {
      width: 150px;
      outline: none;
      border: none;
      box-shadow: var(--glb-shadow);
      border-radius: 3px;
      padding: 5px 0px;
      cursor: pointer;
    }
    .give-testimonial {
      width: 150px;
      outline: none;
      border: none;
      box-shadow: var(--glb-shadow);
      border-radius: 3px;
      padding: 5px 0px;
      cursor: pointer;
    }
    .badge-details {
      width: 150px;
      outline: none;
      border: none;
      box-shadow: var(--glb-shadow);
      border-radius: 3px;
      padding: 5px 0px;
      cursor: pointer;
    }
    .border {
      background-color: var(--glb-btn-general);
      color: #ffffff;
    }
  }

  #referral-code {
    text-align: right;
    float: right;
    .genReferr {
      border-radius: 3px;
      background-color: #4fb91e;
      padding: 12px 20px;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
    }
    #userReferralCode {
      display: flex;
      align-items: center;
      background: rgb(255, 255, 255);
      border: 1px dashed rgb(18, 18, 18);
      padding: 5px 5px;
      #codeToCopy {
        display: inline;
        padding: 10px 0px 10px 10px;
        background: transparent;
        border: none;
      }
      .copyIcon {
        display: inline-block;
        cursor: pointer;
        img {
          width: 23px;
        }
        .tooltip-text {
          visibility: hidden;
          width: 200px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #574f5c;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          margin-top: 20px;
          margin-left: -180px;
          // padding: 5px 0;
          position: absolute;
          // bottom: 25px;
          z-index: 1;
          transition: visibility 0.5s linear;
        }
      }
    }
  }

  .change-password-container {
    .input-field {
      display: block;
      margin: 1rem 0rem;
      label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
        margin-bottom: 5px;
        display: block;
        margin-top: 0.5rem;
      }
      input {
        border: none;
        outline: none;
        border-bottom: 2px solid #a4a4a4;
        background-color: transparent;
        font-size: 20px;
        padding-bottom: 0.3rem;
        box-sizing: border-box;
        display: block;
      }
      input[width="big"] {
        width: 690px;
      }
      input[width="small"] {
        width: 320px;
      }
      input[name="oldPassword"] {
        display: inline-block;
      }
      .password-show-hide {
        position: absolute;
        top: 40%;
        right: 0%;
        cursor: pointer;
      }
      .password-show-hide-1 {
        position: absolute;
        top: 40%;
        right: 0%;
        cursor: pointer;
      }
    }
    #old-password {
      display: inline-block;
    }
    button {
      display: block;
      border: none;
      outline: none;
      width: 235px;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1rem 3rem;
      border-color: #123dfb;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
      cursor: pointer;
    }
    .checkbox-telegram {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 43px;
      .check-declaration {
        display: flex;
        flex-direction: row;
        gap: 2px;
      }
    }
  }
  .tester-bank-table {
    margin-bottom: 3rem;
  }
  .details {
    margin-top: 1rem;
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    font-weight: 500;
    .label-error {
      display: flex;
      flex-direction: row;
      column-gap: 17px;
    }
    .bank {
      padding: 0.7rem 1rem;
      margin-top: 0.7rem;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #cfcfcf;
      background-color: #f3f3f3;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
    }
    .hide {
      visibility: hidden;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    .show-error {
      color: #fc3232;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    .add-name {
      grid-column: 1 / span 2;
      .name {
        box-sizing: border-box;
        padding: 0.7rem 1rem;
        margin-top: 0.7rem;
        width: 100%;
        border-radius: 4px;
        border: solid 1px #cfcfcf;
        background-color: #f3f3f3;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .submit-button {
    display: block;
    font-size: 1.2rem;
    border: none;
    padding: 1rem 5rem;
    text-align: center;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    border-radius: 4px;
    color: var(--glb-txt-primary);
    cursor: pointer;
    background-color: var(--glb-btn-general);
    &:focus {
      outline: none;
    }
  }
  .settings-leaderboard {
    margin-bottom: 1rem;
    line-height: 0;
  }
  .settings-leaderboard-1 {
    line-height: 0;
    .pointsDescription{
      line-height: 24px;
      ul{
        list-style: disc;
      }
    }
  }
  .same-row {
    display: flex;
    .country-list {
      .input-field {
        .input-select {
          outline: none;
          border: none;
          background-color: transparent;
          border-bottom: solid 1px #a4a4a4;
          width: 320px;
          font-size: 19px;
        }
      }
    }
  }
  .field1 {
    margin-right: 50px;
  }
  .form-group {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .img-circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: var(--glb-white);
      border: none;
      outline: none;
      img {
        border-radius: 50%;
        border: none;
        outline: none;
      }
      .removeProfilePicture {
        cursor: pointer;
        width: 80px;
        text-align: center;
        padding: 0.25rem;
        border: 1px solid red;
        background-color: #f7f7f7;
        color: red;
        border-radius: 3px;
      }
    }
    .user-image-label {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0.6rem;
      color: #008080;
      box-sizing: border-box;
      font-size: 0.8rem;
      border: 1px dashed #cfcfcf;
      width: 200px;
      // height: 70%;
      cursor: pointer;
    }
    .editProfilePicture {
      padding: 0.25rem;
      border: 1px solid #f7f7f7;
      background-color: #1aa3e8;
      border-radius: 3px;
      cursor: pointer;
      width: 80px;
      text-align: center;
      color: #fff;
      margin-left: -10px;
    }
    .hide {
      visibility: hidden;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    input.image-input {
      visibility: hidden;
      padding: 0;
      width: 0px;
      height: 0px;
      cursor: pointer;
    }
    .show-error {
      color: #fc3232;
      font-size: 10px;
      padding-top: 0.3rem;
    }
  }
  .img-field {
    border-bottom: none 1px #bcbcbc;
  }
  .savaBtn {
    display: none;
    button {
      padding: 1rem 3rem;
      border: none;
      outline: none;
      border-radius: 4px;
      border-color: #123dfb;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 25px;
    }
  }

  .balanceAmount {
    display: flex;
    max-height: 198px;
    gap: 20px;
    .payment-table {
      width: 517px;
      max-height: 198px;
    }
    .currentBalance {
      margin-top: 40px;
      label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #a4a4a4;
        margin: 22px 76.5px 3px 47px;
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #001464;
        margin: 10px 65.5px 10px 40px;
      }
      button {
        width: 144px;
        height: 35px;
        padding: 7px 12px;
        border: none;
        outline: none;
        border-radius: 7px;
        border-color: #00b212;
        background-color: #00b212;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 10px 47.5px 149px 31px;
      }
    }
  }

  .LangNTimezone {
    //display: flex;
    display: none;
    gap: 35px;
    .same-row-1 {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 786px) {
  .client-dash-settings {
    .tab-buttons {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    #referral-code {
      float: none;
    }
    .change-password-container {
      width: 100%;

      .same-row {
        flex-direction: column;
        width: 100%;
        .country-list {
          .input-field {
            .input-select {
              outline: none;
              border: none;
              background-color: transparent;
              border-bottom: solid 1px #a4a4a4;
              width: 100%;
              font-size: 19px;
            }
          }
        }
        .field1 {
          margin-right: 0px;
          width: 100%;
          .input-field {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
        .field2 {
          margin-right: 0px;
          width: 100%;
          .input-field {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
      }
      .checkbox-telegram {
        flex-direction: column;
      }
      .field1 {
        margin-right: 0px;
        width: 100%;
        .input-field {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
      .settings-change-password {
        .input-field {
          width: 100%;
          label {
            width: 100%;
          }
          input[width="big"] {
            width: 100%;
          }
          input[width="small"] {
            width: 100%;
          }
        }
      }
      button {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .submit-button {
      width: 100%;
    }
    .form-group {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      .img-circle {
        margin-left: auto;
        margin-right: auto;
      }
      .user-image-label {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .LangNTimezone {
      width: 100%;
      flex-direction: column;
      gap: 10px;
    }
    .savaBtn {
      button {
        width: 100%;
      }
    }
  }
}
