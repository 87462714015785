.AboutUsView{
    .half-page-background{
        height: 70vh;
    }
}
@media screen and (max-width: 1200px) {
    .AboutUsView{
        .half-page-background{
            height: 90vh;
        }
    }
    
  }

@media screen and (max-width: 700px) {
    .AboutUsView{
        .half-page-background{
            height: 80vh;
        }
    }
    
  }