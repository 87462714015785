.allTesters {
  .allTesters-btns-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1350px;
    .alltesters-invitation-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;
      .search-tester {
        width: 200px;
        border: none;
        outline: none;
        border-bottom: 1px solid #505050;
        padding-bottom: 5px;
        background-color: transparent;
      }
    }
    .send-invitation-to-all,
    .active-testers {
      padding: 12px 20px;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      &.orange {
        background-color: #fd6529;
      }
      &.blue {
        background-color: #1c76b2;
      }
    }
    .select-tester {
      position: absolute;
      background-color: #fff;
      margin-top: 340px;
      padding: 0px 20px 20px 20px;
      width: 497px;
      height: 240px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #e4e4e4;
      }
      &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }

      .select-tester-item {
        font-size: 0.9rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 1rem 0;
        border-bottom: 1px solid #d3d3d3;
        cursor: pointer;
        display: flex;
        align-items: center;
        .selector {
          width: 19px;
          height: 19px;
          box-sizing: border-box;
          border: solid 1px #2896e9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }
        &:hover {
          .selector .selector-inside {
            height: 15px;
            width: 15px;
            background-image: linear-gradient(224deg, #2896e9 88%, #7467f0 11%);
            border-radius: 50%;
          }
        }
      }
    }
    .displayNone {
      display: none;
    }
    .active-testers {
      margin-left: 1rem;
    }
    .search-inputs {
      width: 700px;
    }
    .search-inputs input {
      padding: 12px 20px;
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: var(--glb-bg-secondary);
    }
    .search-inputs input:first-child {
      margin-right: 20px;
    }
  }
  .no-testers-found {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c2c2c2;
    font-size: 1.2rem;
  }
}
.current {
  background: var(--glb-blue);
  color: #ffffff !important;
}

@media (max-width: 786px) {
  .allTesters {
    .allTesters-btns-container {
      .alltesters-invitation-search {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        row-gap: 20px;
      }
      .active-buttons-media {
        display: grid;
        row-gap: 10px;
      }
    }
  }
}
