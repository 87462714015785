.editModal {
  .formModal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 99;
    .formChildContainer {
      position: relative;
      border-radius: 10px;
      box-shadow: var(--glb-shadow);
      background-color: #ffffff;
      padding: 2rem 1.9rem;
      box-sizing: border-box;
      // min-width: 700px;
      width: 60%;
      min-width: 320px;
      .heading {
        text-align: center;
        margin-bottom: 1.6rem;
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
        margin: 0;
        padding-bottom: 18px;
      }
      #EventEditbadges-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .inputFieldBox {
          margin-bottom: 40px;
          width: 100%;
          label {
            font-size: 14px;
            color: #aaa;
            margin-bottom: 0.5rem;
          }
          .badge-icon-label {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.6rem;
            color: #008080;
            box-sizing: border-box;
            font-size: 0.8rem;
            border: 1px dashed #cfcfcf;
            width: 100%;
            height: 40px;
            cursor: pointer;
            margin-bottom: 0;
          }
          input {
            border: none;
            border-bottom: 1px solid #aaa;
            width: 100%;
            background-color: #fff;
            height: 40px;
            font-size: 16px;
            &:focus {
              outline: none;
              box-shadow: var(--glb-shadow);
            }
          }
          .badge-icon {
            display: none;
            opacity: 0;
            padding: 0;
            width: 0px;
            height: 0px;
            cursor: pointer;
          }
        }
        #badgeIconContainer {
          display: flex;
          align-items: flex-end;
        }
        #Eventsubmit-badge {
          background-color: #4fb91e;
          padding: 12px 20px;
          color: #fff;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          width: 200px;
        }
      }
      #close-popUpEditEvent {
        position: absolute;
        top: 2%;
        right: 5%;
        background: none;
        border: none;
        box-shadow: var(--glb-shadow);
        outline: none;
        color: #d20000;
        font-size: 36px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .formModal {
    .formChildContainer {
      width: 90%;
      #badges-form {
        .inputFieldBox {
          width: 100%;
          .badge-icon-label,
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
