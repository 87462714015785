.faq_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .heading {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .faq {
    width: 80%;

    .active {
      box-shadow: var(--glb-shadow);
    }

    .list-item {
      border-radius: 4px;
      border: solid 2px #d4dfff;
      background-color: #ffffff;
      //padding: 10px 18px 0px 21px;
      margin-bottom: 30px;
      .list-item-title {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
      }
      p {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        background-color: #ffffff;
        padding: 10px 200px 10px 0px;
        margin: 0 10px;
      }
    }
  }
}

@media (max-width: 786px) {
  .faq_container {
    margin: 0;
    .faq {
      width: 90%;
      .list-item {
        // .list-item-title {
        //   line-height: 1.5;
        // }
        p {
          padding: 10px 0px 20px 0px;
        }
      }
    }
  }
}
