.dashboard-layout {
  box-sizing: border-box;
  position: relative;
  padding-top: 85px;
  height: 100vh;
  .dashboard-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    .dashboard-nav {
      overflow: auto;
      width: 18%;
      box-shadow: var(--glb-shadow);
      z-index: 10;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #e4e4e4;
      }
      &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }
    }
    .dash-nav-none {
      display: none;
    }
    .dashboard-body {
      .dashboard-toggle-btn-container {
        display: none;
      }
      overflow: auto;
      box-sizing: border-box;
      background-color: var(--glb-bg-secondary);
      padding: 2rem;
      width: 100%;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #e4e4e4;
      }
      &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }
      .sidebarDashboard {
        display: block;
        width: max-content;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .dashboard-layout {
    padding-top: 73px;
  }
}

@media (max-width: 1040px) {
  .dashboard-layout {
    padding-top: 25px;
    .dashboard-main-container {
      .dashboard-nav {
        padding-top: 64px;
        box-sizing: border-box;
        position: absolute;
        width: 250px;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: #fff;
        &.hide {
          display: none;
        }
      }
      .dashboard-body {
        padding-top: 70px;
        .dashboard-toggle-btn-container {
          display: block;
          display: flex;
          margin-bottom: 1.2rem;
          .dashboard-toggle-btn {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .icon {
              height: 25px;
              width: 25px;
              margin-right: 0.5rem;
              img {
                display: block;
                height: 100%;
                width: 100%;
              }
            }
            .heading {
              cursor: pointer;
              font-size: 1.4rem;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.32;
              letter-spacing: normal;
              text-align: left;
              color: #172b4d;
            }
          }
          max-width: max-content;
        }
      }
    }
  }
}
