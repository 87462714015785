.component2 {
  box-shadow: var(--glb-bg-secondary);
  padding-top: 50px;
  margin: 0 auto;
  padding-bottom: 75px;
  background-color: var(--glb-light);
  .component2-heading {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .icons-and-description {
    margin: 1px auto;
    width: 1375px;
    text-align: center;
  }
  .icon-and-description-img {
    display: none;
  }
  .icons-placement {
    margin: 1px auto;
    width: 1375px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .styled-border {
    width: 1375px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    margin-top: 23px;
    margin-bottom: 15px;
    .first-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .first-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .second-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .second-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .third-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .third-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .fourth-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
  }
  .icons-content {
    width: 1375px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .content-first {
      width: 250px;
      height: 44px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-second {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-third {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-fourth {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
  }
  .component2-grid {
    width: 100%;
    margin: 1px auto 1px auto;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(1, 1fr);
    // column-gap: 35px;
    display: flex;
    justify-content: space-evenly;

    .benefitsSeciton{
      list-style: none;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1.5px;
      .benefitsItem{
        line-height: 4rem;
        img{
          vertical-align: middle;
        }
      }
    }
    .component2-box-container {
      display: flex;
      justify-content: center;
    }
    .component2-box {
      width: 310px;
      height: 80px;
      padding: 0.5rem 1rem;
      // padding: 12px 10px 0px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;
      border-radius: 4px;
      box-shadow: var(--glb-shadow);
      background-color: #fff;
      color: var(--glb-txt-primary);
      .white-tick {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: var(--glb-white);
        box-shadow: var(--glb-shadow);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
      }
    }
  }
  .component2-content-btn {
    text-align: center;
    .component2-content {
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: #3d3d3d;
      margin-top: 40px;
      margin-bottom: 60px;
      .component2-content-highlight {
        font-weight: bold;
      }
    }
    .component2-btn {
      width: 360px;
      // height: 54px;
      margin: 1px auto 1px auto;
      // padding: 20px 0px 0px 0px;
      // text-align: center;
      // font-size: 28px;
      // font-weight: 600;
      // border-radius: 4px;
      // background-color: var(--glb-btn-general);
      // color: var(--glb-txt-primary);
      // cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1510px) {
  .component2 {
    .icons-and-description {
      width: 100%;
    }
    .icons-placement {
      width: 100%;
    }
    .icons-content {
      width: 100%;
    }
    .styled-border {
      width: 100%;
      .first-empty-div {
        width: 22%;
      }
      .second-empty-div {
        width: 22%;
      }
      .third-empty-div {
        width: 22%;
      }
    }
    .component2-grid {
      width: 100%;
      margin: 1px auto 1px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 35px;
      .component2-box-container {
        justify-content: flex-start;
        &:nth-child(odd) {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .component2 {
    .icons-and-description {
      .icons-placement {
        display: none;
      }
      .styled-border {
        display: none;
      }
      .icons-content {
        display: none;
      }
      .icon-and-description-img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .component2-grid {
      display: flex;
      flex-direction: column;
      .component2-box-container {
        justify-content: center;
        &:nth-child(odd) {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
        }
      }
    }
    .component2-content-btn {
      text-align: center;
      .component2-content {
        width: 320px;
        height: 72px;
        margin: 30px auto 60px auto;
      }
      .component2-btn {
        width: 209px;
        //height: 43px;
        //padding: 16px 0px 0px 0px;
        text-align: center;
        font-size: 20px;
      }
    }
  }
}
