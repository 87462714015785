.device-farm-comp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  .devices-list-container {
    width: 1182px;
    height: 600px;
    margin-top: 3rem;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    .pagination-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .pagination {
        display: flex;
        align-items: center;
        .element {
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: center;
          color: #7e8c94;
          height: 28px;
          width: 28px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-sizing: border-box;
          margin: 2rem 0.5rem;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently*/
        }
        .controller {
          font-weight: bold;
        }
        .number {
          font-size: 0.8rem;
        }
        .current {
          background:var(--glb-blue);
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .device-farm-comp {
    .devices-list-container {
      width: 90%;
      height: auto;
      .pagination-container {
        .pagination {
          width: 100%;
          justify-content: center;
          gap: 10px;
          .element {
            margin: 15px -5px 10px -5px;
            height: auto;
          }
        }
      }
    }
  }
}
