.signup-form {
  background: white;
  padding: 1rem 5rem 2.2rem 5rem;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  width: 60vw;
  .form-heading {
    font-size: 1.9rem;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .form-submit {
    flex-wrap: wrap;
    #google,
    #linkedin {
      background-color: transparent;
      width: 45%;
      height: 45px;
      text-align: center;
      padding: 10px;
      outline: none;
      border: 1px solid #0333f3;
      border-radius: 10px;
      color: #1f1f1f;
      box-shadow: var(--glb-shadow);
      cursor: pointer;
      min-width: fit-content;
    }
  }
  .form-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9rem;
    .previouButton {
      border: none;
      background: none;
      color: #123dfb;
      font-size: 36px;
    }
    .form-group {
      position: relative;
      label,
      &:nth-child(odd) {
        margin-right: 1rem;
      }
      &:nth-child(even) {
        margin-left: 1rem;
      }
      label {
        color: #a4a4a4;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
      }
      input[name="passwordRep"] {
        display: inline-block;
      }
      .password-show-hide {
        position: absolute;
        top: 37%;
        right: 4%;
        cursor: pointer;
      }
      input,
      select {
        padding-bottom: 0.3rem;
        display: flex;
        border: none;
        width: 100%;
        padding-top: 0.1rem;
        padding: 10px 5px;
        margin-top: 5px;
        box-sizing: border-box;
        font-size: 1.2rem;
        //border-bottom: solid 1px #bcbcbc;
        border: 1px solid #123dfb;
        border-radius: 5px;
        color: #4b4b4b;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          font-size: 0.9rem;
        }
        &::placeholder,
        &::-ms-input-placeholder,
        &:-ms-input-placeholder {
          color: #a4a4a4;
        }
      }
      #countryList:focus option:first-of-type {
        display: none;
      }
      .user-image-label {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0.6rem;
        color: #008080;
        box-sizing: border-box;
        font-size: 0.8rem;
        border: 1px dashed #cfcfcf;
        width: 100%;
        // height: 70%;
        cursor: pointer;
      }
      .hide {
        visibility: hidden;
        font-size: 10px;
        padding-top: 0.3rem;
      }
      input.image-input {
        visibility: hidden;
        padding: 0;
        width: 0px;
        height: 0px;
        cursor: pointer;
      }
      .show-error {
        color: #fc3232;
        font-size: 10px;
        padding-top: 0.3rem;
      }
    }
    .img-field {
      border-bottom: none 1px #bcbcbc;
    }
  }
  .form-check-box {
    margin-top: 2rem;
    margin-left: 20%;
    .form-group {
      margin-bottom: 0.3rem;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 0.8rem;
      color: #565656;
      &:nth-child(2) {
        margin-left: 0px;
      }
      input {
        display: block;
        margin-right: 0.5rem;
        width: fit-content;
      }
      span {
        display: block;
        u {
          .form-link {
            text-decoration: none;
            color: #247cd3;
          }
        }
      }
    }
  }
  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    button {
      border: none;
      box-shadow: var(--glb-shadow);
      font-size: 1.1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 14px 82px 14px 80px;
      border-radius: 4px;
      background-color: var(--glb-btn-general);
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 786px) {
  .signup-form {
    padding: 1.5rem;
    box-sizing: border-box;
    width: 90vw;
    margin-bottom: 2rem;
    padding-bottom: 2.5rem;
    .form-inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      .form-group {
        label,
        &:nth-child(odd) {
          margin-right: 0rem;
        }
        &:nth-child(even) {
          margin-left: 0rem;
        }
      }
    }
    .form-check-box {
      margin-left: 0%;
    }
  }
}
