.dashboard-active-tests {
  padding: 1.5rem 1.3rem;
  border-radius: 10px;
  width: 316px;
  min-height: 615px;
  box-sizing: border-box;
  box-shadow: var(--glb-shadow);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  small {
    font-size: 0.8rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #a4a4a4;
    display: block;
    margin-bottom: 0.3rem;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #505050;
    margin-bottom: 0.3rem;
  }
  .comp-logo {
    height: 83px;
    margin-bottom: 1.7rem;
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
    img {
      display: block;
      height: 100%;
      max-width: 240px;
    }
  }
  .name,
  .timings {
    margin-bottom: 1rem;
  }
  .platform {
    margin-bottom: 1rem;
    .onlineprojects-platform-container {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      padding: 0.8rem 0;
      .platform-icons {
        width: 25px;
        height: 30px;
        img {
          display: block;
          height: 100%;
        }
      }
      .platform-name {
        font-size: 0.95rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        color: #b9b9b9;
        margin-left: 0.2rem;
      }
    }
  }
  .timings {
    .time-disc {
      display: flex;
      justify-content: center;
      align-items: center;
      .desc {
        margin-right: 0.9rem;
      }
    }
  }

  .leaderboard {
    .leaderboard-leaders {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 131px;
      .leader {
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .image {
          height: 47px;
          width: 47px;
          img {
            display: block;
            height: 100%;
            width: 100%;
            border: solid 1px #707070;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 10px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: center;
          color: #505050;
          margin: 0.5rem 0;
        }
        .amount,
        .earned {
          font-size: 10px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: center;
          color: #a4a4a4;
        }
        .amount {
          color: #103dfa;
        }
      }
    }
  }

  .begin-testing {
    justify-content: center;
    width: 100%;
    margin-top: 0.9rem;
    button {
      width: 100%;
      padding: 0.7rem 2rem;
      border: none;
      border-radius: 4px;
      background-color: #001e98;
      font-size: 0.9rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
  .active-test-bug-list {
    justify-content: center;
    width: 100%;
    margin-top: 0.9rem;
    button {
      width: 100%;
      padding: 0.7rem 2rem;
      border: 2px solid #001e98;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #001e98;
      background: #ffffff;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .dashboard-active-tests {
    padding: 1.3rem 1rem;
    width: 316px;
    min-height: 575px;
    small {
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #a4a4a4;
      display: block;
      margin-bottom: 0.3rem;
    }
    h4 {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #505050;
      margin-bottom: 0.3rem;
    }
    .comp-logo {
      height: 75px;
      margin-bottom: 1.3rem;
    }
    .name,
    .timings {
      margin-bottom: 0.8rem;
    }
    .platform {
      margin-bottom: 0.8rem;
      .onlineprojects-platform-container {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 0;
        .platform-icons {
          width: 25px;
          height: 30px;
          img {
            display: block;
            height: 100%;
          }
        }
        .platform-name {
          font-size: 0.9rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: normal;
          text-align: left;
          color: #b9b9b9;
          margin-left: 0.2rem;
        }
      }
    }
    .timings {
      .time-disc {
        display: flex;
        justify-content: center;
        align-items: center;
        .desc {
          margin-right: 0.8rem;
        }
      }
    }

    .leaderboard {
      .leaderboard-leaders {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 131px;
        .leader {
          margin: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .image {
            height: 38px;
            width: 38px;
            img {
              display: block;
              height: 100%;
              width: 100%;
              border: solid 1px #707070;
              border-radius: 50%;
            }
          }
          .name {
            font-size: 10px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: center;
            color: #505050;
            margin: 0.5rem 0;
          }
          .amount,
          .earned {
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: center;
            color: #a4a4a4;
          }
          .amount {
            color: #103dfa;
          }
        }
      }
    }

    .begin-testing {
      justify-content: center;
      width: 100%;
      margin-top: 0.9rem;
      button {
        width: 100%;
        padding: 0.6rem 2rem;
        border: none;
        border-radius: 4px;
        background-color: #001e98;
        font-size: 0.9rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    .active-test-bug-list {
      justify-content: center;
      width: 100%;
      margin-top: 0.9rem;
      button {
        width: 100%;
        padding: 0.6rem 2rem;
        border: 2px solid #001e98;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #001e98;
        background: #ffffff;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .dashboard-active-tests {
    min-width: 315px;
    padding: 2rem;
  }
}
