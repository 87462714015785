.choose-platform-container {
  background: white;
  padding: 1rem 3rem 2.2rem 3rem;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  margin-top: 3rem;
  min-height: 610px;
  height: 100%;
  min-width: 1270px;
  box-sizing: border-box;
  .form-heading {
    font-size: 1.5rem;
    color: #313131;
    text-align: center;
  }

  .checkout {
    display: flex;
    margin-top: 4rem;
    .form {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      form {
        max-width: 500px;
        margin-right: 1.5rem;
      }
      .form-inputs {
        .form-group {
          width: 100%;
          border-bottom: solid 1px #bcbcbc;
          padding-bottom: 0.3rem;
          margin-bottom: 1.7rem;
          label {
            color: #a4a4a4;
            font-size: 0.8rem;
          }
          input {
            display: flex;
            border: none;
            padding-top: 0.1rem;
            font-size: 1.2rem;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .terms {
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #565656;
      }
      .form-submit-checkout {
        margin-top: 2rem;
        input {
          border: none;
          box-shadow: var(--glb-shadow);
          font-size: 1.1rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          padding: 14px 82px 14px 80px;
          border-radius: 4px;
          background-color: #009400;
        }
      }
    }
  }
  .platform-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    .navArrow {
      cursor: pointer;
      display: block;
    }
    .platforms {
      display: flex;
      // justify-content: space-around;
      align-items: center;
      max-width: 1020px;
      overflow-x: hidden;
      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 207px;
        min-height: 207px;
        margin: 0 1.5rem;
        border-radius: 10px;
        text-align: center;
        background-color: #e5e5e5;
        transition: 0.5s;
        cursor: pointer;
        div {
          margin-top: 1rem;
          font-size: 17px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.24;
          letter-spacing: normal;
          text-align: center;
          color: #505050;
          cursor: pointer;
        }
      }
      .platform.selected {
        background-color: #002a7e;
        color: #ffffff;
        fill: #ffffff;
        div {
          color: #ffffff;
        }
      }
    }
  }
  .inputs {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .input-container {
      width: 50%;
    }
    // label, .label{
    //   display: block;
    //   font-size: 0.8rem;
    //   color: #a4a4a4;
    //   padding-bottom: 0.4rem;
    // }
    // .input-field{
    //   margin-bottom: 0.5rem;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    //   text-align: center;
    //   input{
    //     display: block;
    //     width: 60%;
    //     border: none;
    //     font-size: 1.2rem;
    //     text-align: center;
    //     color: #505050;
    //     width: 100%;
    //     padding-bottom: 0.5rem;
    //     border-bottom: solid 2px #bcbcbc;
    //     &:focus{
    //       outline: none;
    //     }
    //   }
    // }
    // .upload-field{
    //   .file-upload{
    //     display: block;
    //     background-color: #007a04;
    //     color: #ffffff;
    //     padding: 0.25rem 0;
    //     margin-bottom: 0.5rem;
    //     border-radius: 4px;
    //     display: flex;
    //     font-weight: 600;
    //     align-items: center;
    //     span{
    //       font-size: 11px;
    //       display: inline-block;
    //       padding: 0 1.1rem 0 0.5rem;
    //       &:first-child{
    //         padding: 0 0.4rem;
    //         border-right: solid 1px #ffffff;;
    //       }
    //       img{
    //         display: block;
    //         width: 25px;
    //         height: auto;
    //       }
    //     }
    //   }
    //   .file-upload.apk{
    //     background: #529cf7;
    //   }
    // }
  }

  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto 0;
    width: max-content;
    button {
      border: none;
      box-shadow: var(--glb-shadow);
      font-size: 1.1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 14px 82px 14px 80px;
      border-radius: 4px;
      background-color: var(--glb-btn-general);
      &:disabled {
        background: #b4b4b4;
      }
    }
  }
}

.navArrow {
  cursor: pointer;
  display: block;
  border: none;
  margin: 0 4px;
}

.select-platform-time {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  max-width: 539px;
  .select-platform-time-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    min-width: 535px;
    transition: 0.5s;
    .input-fields {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 145px;
      padding-bottom: 5px;
      border-bottom: solid 2px #bcbcbc;
      label {
        display: block;
        margin-bottom: 0.6rem;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #a4a4a4;
      }
      input {
        display: block;
        border: none;
        width: 100%;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: #505050;
        &:focus {
          outline: none;
        }
      }
      &:last-child {
        input {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: #00255b;
        }
      }
    }
    .logo {
      width: 53px;
      height: 53px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cart {
  flex: 1;
  .cart-details {
    display: flex;
    justify-content: center;
    align-items: center;
    .summary-card {
      position: relative;
      width: 370px;
      padding: 17px 22.8px 43px 28px;
      box-shadow: var(--glb-shadow);
      background-color: #f3fffd;
      .cart-icon {
        position: absolute;
        top: -30px;
        left: 40px;
        padding: 10px;
        border-radius: 4px;
        background-color: var(--glb-btn-general);
      }
      h2 {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #565656;
      }
      .section {
        border-bottom: solid 2px #dcdcdc;
        padding-top: 0.2rem;
        padding-bottom: 1rem;
        h3 {
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: left;
          color: #505050;
        }
        .items {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            text-align: left;
            color: #707070;
          }
        }
      }
      .total {
        padding-bottom: 0.2rem;
        padding-top: 1rem;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #565656;
        }
      }
    }
  }
}

.sub-total {
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  .sub-total-inner {
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dcdcdc;
    color: #002a7e;
    box-shadow: var(--glb-shadow);
    .icon {
      border-radius: 28px 0 0 28px;
      background: #002a7e;
      padding: 0.8rem;
      height: 30px;
      width: 30px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .details {
      padding: 0 1.5rem 0 0.8rem;
      font-size: 1.5rem;
      height: 100%;
      font-weight: bold;
    }
  }
}
