.display-none {
  display: none;
}
.footer-main-container {
  padding: 64px 100px 74px;
  // background-image: url("../../../assets/images/footer-bg.png");
  //background-color: var(--glb-bg-secondary);
  background-color: #eeeeee;
  box-shadow: var(--glb-shadow);
  color: var(--glb-txt-primary);
  background-size: 100% 100%;
  .footer-navigation {
    display: flex;
    //align-items: center;
    margin-bottom: 40px;
    .footer-logo-container {
      flex: 1;
      .footer-logo {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .footer-navitems-container {
      flex: 4;
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          padding: 0 10px;
        }
      }
    }
    .footer-social-container {
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          padding: 0 10px;
        }
      }
      .socialMediaIcon {
        width: 35px;
        height: 35px;
      }
    }
  }
  .divider1 {
    width: 100%;
    border-bottom: 1px solid #ffffff;
    opacity: 0.44;
    margin: 34.5px 0;
    display: none;
  }
  .footer-otherdetails-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .copyright-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .divider2 {
        width: 100%;
        border-bottom: 1px solid #ffffff;
        opacity: 0.44;
        margin: 34.5px 0;
        display: none;
      }
      .new-text {
        display: none;
      }
    }
    .company-connection {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .nexgenlogo {
      margin-top: 30px;
    }
  }
  .other-text {
    margin: 6.5px 0;
  }
  .terms-privacy {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .other-text a {
    text-decoration: none;
  }
}
@media (max-width: 786px) {
  .footer-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    .footer-navigation {
      display: flex;
      flex-direction: column;
      .footer-logo-container {
        .footer-logo img {
          width: 30%;
        }
      }
      .footer-navitems-container {
        display: none;
      }
      .footer-social-container {
        ul {
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            padding: 25px 20px 0px 20px;
          }
        }
        .socialMediaIcon {
          width: 35px;
          height: 35px;
        }
      }
    }
    .footer-otherdetails-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .copyright-details {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        .divider2 {
          width: 100%;
          border-bottom: 1px solid #ffffff;
          opacity: 0.44;
          margin: 34.5px auto;
          display: flex;
          justify-content: center;
        }
        .new-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 10px auto;
        }
        .terms-privacy {
          display: grid;
          grid-template-columns: none;
          grid-template-rows: repeat(2, 1fr);
          gap: 15px;
        }
      }
      .company-connection {
        .other-text {
          display: none;
        }
      }
    }
  }
}
