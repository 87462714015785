.boostmultiplyer-skills {
  .add-skills {
    margin-top: 0.5rem;
    margin-bottom: 2.3rem;
    position: relative;
    .select-skill {
      position: absolute;
      background-color: #fff;
      padding: 0px 20px 20px 20px;
      width: 497px;
      height: 240px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #e4e4e4;
      }
      &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #929292;
      }

      .select-skill-item {
        font-size: 0.9rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 1rem 0;
        border-bottom: 1px solid #d3d3d3;
        cursor: pointer;
        display: flex;
        align-items: center;
        .selector {
          width: 19px;
          height: 19px;
          box-sizing: border-box;
          border: solid 1px #2896e9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }
        &:hover {
          .selector .selector-inside {
            height: 15px;
            width: 15px;
            background-image: linear-gradient(224deg, #2896e9 88%, #7467f0 11%);
            border-radius: 50%;
          }
        }
      }
    }
    .displayNone {
      display: none;
    } 
    .card {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.7rem;
      border-bottom: 2px solid #d6d8d9;
      width: 500px;
      .img {
        height: 17px;
      }
      input {
        display: block;
        margin-left: 1rem;
        font-size: 1.2rem;
        width: 100%;
        border: none;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
    }
    #searchbar{
      width: 100%;
      max-width: 500px;
    }
  }
  .skillset-grid {
    // width: 1050px;
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 10px;
  }
  .no-skills-found{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c2c2c2;
    font-size: 1.2rem;
  }
}
.rankError{
    text-align: center;
    color: red;
    font-weight: 600;
    letter-spacing: 3px;
}
@media   (max-width: 786px){
  .boostmultiplyer-skills{
    width: 100%;
    .add-skills{
      .card{
        width: 90%;
      }
    }
    .skillset-grid{
      width: 100%;
      // grid-template-columns: repeat(1, 1fr);
    }
  }
} 
