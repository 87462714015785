
//common css function

@mixin reviewButton() {
  border: none;
  outline: none;
  width: 70px;
  padding: 5px;
  background-color: rgb(247, 54, 54);
  color: var(--glb-txt-primary);
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: strech;
  color:var(--glb-white);
  padding: 10px 5px;
}


.testimonialApproval {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .testimonialApprovalModal {
    padding: 2rem;
    width: 100%;
    max-width: 300px;
    background: #fff;
    border-radius: 5px;
    box-shadow: var(--glb-shadow);
    .edit-testimonial {
      margin-top: 20px;
      width: 100%;
      max-width: 250px;
      height: 160px;
      outline: none;
      background-color: rgb(255, 255, 255);
      border: none;
      border-radius: 15px;
      padding: 1rem;
      line-height: 1.5;
      box-shadow: var(--glb-shadow);
    }
    .approve-reject {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 10px;
      .update {
        @include reviewButton();
        background-color: rgb(247, 152, 10);
      }
      .approve {
        @include reviewButton();
        background-color: rgb(40, 179, 6);
      
      }
      .reject {
        @include reviewButton();
        background-color: rgb(247, 54, 54);
      }
    }
  }
}