.platform-select-container {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #233137;
    }
    .close-icon {
      height: 28px;
      width: 28px;
      text-align: left;
      cursor: pointer;
    }
  }

  .platform-select-component-with-name {
    display: flex;
    flex-wrap: wrap;
    .platform {
      &.selected {
        background-color: #0084dc;
        color: #ffffff;
        &:hover {
          background-color: #0084dc;
          color: #ffffff;
        }
      }
      &.disabled{
        background-color: #e1e1e1;
        color: #c5c5c5;
        cursor: default;
        .platform-name{
          cursor: default;
        }
        &:hover {
          background-color: #e1e1e1;
          color: #c5c5c5;
        }
      }
      border: solid 1px #cbcbcb;
      color: #565656;
      padding: 0.5rem 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.9rem;
      margin-bottom: 0.9rem;
      cursor: pointer;
      transition: all 0.2s;
      .platform-name {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        cursor: pointer;
      }
      &:hover {
        background-color: #d5d5d5;
      }
    }
  }

  .platform-select-component-with-icon {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    box-sizing: border-box;
    gap: 1.7rem;
    .platform {
      &.selected {
        background-color: #002a7e;
        color: #ffffff;
        &:hover {
          background-color: #002a7e;
          color: #ffffff;
        }
      }
      background: #e5e5e5;
      // color: #505050;
      box-sizing: border-box;
      padding: 0.5rem;
      height: 139px;
      width: 139px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
      .icon {
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: block;
          height: 100%;
        }
      }
      .platform-name {
        margin-top: 0.3rem;
        font-size: 0.7rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
      }
      &:hover {
        background-color: #d5d5d5;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .platform-select-container {
    align-items: center;
    justify-items: center;
    padding-top: 5rem;
    .platform-select-component-with-icon {
      gap: 1.4rem;
      .platform {
        height: 120px;
        width: 120px;
        transition: all 0.2s;
        .icon {
          margin-top: 0.5rem;
          width: 60px;
          height: 60px;
        }
        .platform-name {
          margin-top: 0.5rem;
          font-size: 0.6rem;
        }
  
      }
    }
  }
  
}
@media  screen and (max-width: 786px){
  .platform-select-container {
    padding-top: 0px;
    .platform-select-component-with-icon {
      gap: 0.8rem;
    }
  }
}
@media (max-width: 752px) {
  .platform-select-container {
    padding-top: 2rem;
  }
}
@media (max-width: 688px) {
  .platform-select-container {
    padding-top: 5rem;
  }
}

@media (max-width: 637px) {
  .platform-select-container {
    padding-top: 14rem;
    .platform-select-component-with-icon {
      gap: 0.8rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media (max-width: 608px) {
  .platform-select-container {
    padding-top: 16rem;
  }
}
@media (max-width: 560px) {
  .platform-select-container {
    padding-top: 19rem;
  }
}
@media (max-width: 505px) {
  .platform-select-container {
    padding-top: 22.5rem;
  }
}
@media only screen and (max-width: 485px) {
  .platform-select-container {
    padding-top: 20rem;
    .platform-select-component-with-icon {
      .platform {
        height: 100px;
        width: 100px;
        transition: all 0.2s;
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 475px) {
  .platform-select-container {
    padding-top: 22.5rem;
  }
}
@media (max-width: 444px) {
  .platform-select-container {
    padding-top: 25rem;
  }
}
@media (max-width: 425px) {
  .platform-select-container {
    padding-top: 23rem;
    .platform-select-component-with-icon {
      .platform {
        height: 90px;
        width: 90px;
        transition: all 0.2s;
        .icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
@media (max-width: 416px) {
  .platform-select-container {
    padding-top: 26rem;
  }
}
@media (max-width: 400px) {
  .platform-select-container {
    padding-top: 26rem;
    .platform-select-component-with-icon {
      gap: 0.5rem;
      .platform {
        height: 90px;
        width: 90px;
        .icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
@media (max-width: 396px) {
  .platform-select-container {
    padding-top: 29rem;
  }
}
@media (max-width: 392px) {
  .platform-select-container {
    padding-top: 32rem;
  }
}
@media (max-width: 382px) {
  .platform-select-container {
    padding-top: 33rem;
    .platform-select-component-with-icon {
      .platform {
        height: 80px;
        width: 80px;
        .icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
@media (max-width: 372px) {
  .platform-select-container {
    padding-top: 34rem;
  }
}
@media (max-width: 360px) {
  .platform-select-container {
    padding-top: 38rem;
  }
}

@media (max-width: 345px) {
  .platform-select-container {
    padding-top: 46rem;
    .platform-select-component-with-icon {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 322px) {
  .platform-select-container {
    padding-top: 50rem;
  }
}
@media (max-width: 318px) {
  .platform-select-container {
    padding-top: 52rem;
  }
}
@media (max-width: 310px) {
  .platform-select-container {
    padding-top: 55rem;
  }
}