.addtest-step2 {
  .form {
    .form-heading {
      font-size: 1.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
      margin-bottom: 1.5rem;
    }
    label,
    .addtest-input-label {
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #606060;
      display: block;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5rem;
    }
    .logo-uploader {
      .appLogo {
        width: 80px;
        height: 80px;
        border-radius: 15px;
      }
      label {
        width: 100%;
        border: 1px dotted #cfcfcf;
        text-align: center;
        padding: 0.5rem 2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        box-sizing: border-box;
        color: #008080;
        margin: 0;
        cursor: pointer;
      }
      input {
        visibility: hidden;
      }
    }
    .addtest-dropdown {
      width: 100%;
      margin-bottom: 1.2rem;
      .tooltip {
        font-size: 1rem;
        position: relative;

        &:hover{
          cursor: pointer;
          .tooltip-text {
            visibility: visible;
          }
        } 
        .tooltip-text {
          visibility: hidden;
          width: 100%;
          background-color: #574f5c;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          bottom: 25px;
          z-index: 1;
        }
      }

      
      
      select {
        box-sizing: border-box;
        width: 100%;
        display: block;
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 0.5rem;
        background-color: #f3f3f3;
        border: solid 1px #cfcfcf;
        outline: none;
      }
    }
    .addtest-input-stp-2 {
      width: 100%;
      margin-bottom: 1.2rem;
      input {
        box-sizing: border-box;
        width: 100%;
        display: block;
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        padding: 0.5rem;
        background-color: #f3f3f3;
        border: solid 1px #cfcfcf;
        outline: none;
      }
    }
    .addtest-description {
      textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 119px;
        background-color: #f3f3f3;
        border: solid 1px #cfcfcf;
        outline: none;
        padding: 0.5rem;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
