.singleTestimonial {
  width: 100%;
  max-width: 300px;
  height: max-content;
  background: #fff;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  padding: 20px;
  position: relative;
  word-wrap: break-word;
  min-height: 220px;
  .testimonial-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    .testerName {
      display: flex;
      align-items: center;
      font-weight: bold;
      gap: 7px;
      .testimonialTesterImage {
        vertical-align: middle;
        border-radius: 50%;
      }
    }
    .testimonial-status {
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
      border-radius: 50%;
      border: 1px;
    }
  }
  .testimonial-div {
    margin-bottom: 30px;
    text-align: justify;
    .more-less-btn {
      border: none;
      outline: none;
      background: transparent;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .review-btn {
     position: absolute;
    bottom: 10px;
    min-width: 100px;
    padding: 5px;
    background-color: var(--glb-grey);
    color: var(--glb-txt-primary);
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
  }
}
