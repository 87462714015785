.client-dash-settings {
  .change-password-container {
    .input-field {
      position: relative;
      display: block;
      margin: 1rem 0rem;
      label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
        margin-bottom: 5px;
        display: block;
        margin-top: 0.5rem;
      }
      input {
        border: none;
        outline: none;
        border-bottom: 2px solid #a4a4a4;
        background-color: transparent;
        font-size: 20px;
        padding-bottom: 0.3rem;
        box-sizing: border-box;
        display: block;
      }
      input[width="big"] {
        width: 690px;
      }
      input[width="small"] {
        width: 320px;
      }
      input[name="oldPassword"] {
        display: inline-block;
      }

      .password-show-hide {
        position: absolute;
        top: 40%;
        right: 0%;
        cursor: pointer;
      }
      .password-show-hide-1 {
        position: absolute;
        top: 40%;
        right: 0%;
        cursor: pointer;
      }
    }
    #old-password {
      display: inline-block;
    }
    button {
      display: block;
      border: none;
      outline: none;
      width: 235px;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1rem 3rem;
      border-color: #123dfb;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
      cursor: pointer;
    }
  }
  .settings-leaderboard {
    margin-bottom: 1rem;
    line-height: 0;
  }
  .settings-leaderboard-1 {
    line-height: 0;
  }
  .same-row {
    display: inline-flex;
    .country-list {
      .input-field {
        .input-select {
          outline: none;
          border: none;
          background-color: transparent;
          border-bottom: solid 1px #a4a4a4;
          width: 320px;
          font-size: 19px;
        }
      }
    }
  }
  .field1 {
    margin-right: 50px;
  }
  .form-group {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: var(--glb-white);
      border: none;
      outline: none;
      img {
        border-radius: 50%;
        border: none;
        outline: none;
      }
    }
    .user-image-label {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0.6rem;
      color: #008080;
      box-sizing: border-box;
      font-size: 0.8rem;
      border: 1px dashed #cfcfcf;
      width: 200px;
      // height: 70%;
      cursor: pointer;
    }
    .hide {
      visibility: hidden;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    input.image-input {
      visibility: hidden;
      padding: 0;
      width: 0px;
      height: 0px;
      cursor: pointer;
    }
    .show-error {
      color: #fc3232;
      font-size: 10px;
      padding-top: 0.3rem;
    }
  }
  .img-field {
    border-bottom: none 1px #bcbcbc;
  }
  .savaBtn {
    display: none;
    button {
      padding: 1rem 3rem;
      border: none;
      outline: none;
      border-radius: 4px;
      border-color: #123dfb;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 25px;
    }
  }

  .balanceAmount {
    display: flex;
    max-height: 198px;
    gap: 20px;
    .payment-table {
      width: 517px;
      max-height: 198px;
    }
    .currentBalance {
      margin-top: 40px;
      label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #a4a4a4;
        margin: 22px 76.5px 3px 47px;
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #001464;
        margin: 10px 65.5px 10px 40px;
      }
      button {
        width: 144px;
        height: 35px;
        padding: 7px 12px;
        border: none;
        outline: none;
        border-radius: 7px;
        border-color: #00b212;
        background-color: #00b212;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 10px 47.5px 149px 31px;
      }
    }
  }

  .LangNTimezone {
    //display: flex;
    display: none;
    gap: 35px;
    .same-row-1 {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 786px) {
  .client-dash-settings {
    .change-password-container {
      width: 100%;

      .same-row {
        flex-direction: column;
        width: 100%;
        .country-list {
          .input-field {
            .input-select {
              outline: none;
              border: none;
              background-color: transparent;
              border-bottom: solid 1px #a4a4a4;
              width: 100%;
              font-size: 19px;
            }
          }
        }
        .field1 {
          margin-right: 0px;
          width: 100%;
          .input-field {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
        .field2 {
          margin-right: 0px;
          width: 100%;
          .input-field {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
      }
      .field1 {
        margin-right: 0px;
        width: 100%;
        .input-field {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
      .settings-change-password {
        .input-field {
          width: 100%;
          label {
            width: 100%;
          }
          input[width="big"] {
            width: 100%;
          }
          input[width="small"] {
            width: 100%;
          }
        }
      }
      button {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }
    .form-group {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      .img-circle {
        margin-left: auto;
        margin-right: auto;
      }
      .user-image-label {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .LangNTimezone {
      width: 100%;
      flex-direction: column;
      gap: 10px;
    }
    .savaBtn {
      button {
        width: 100%;
      }
    }
  }
}
