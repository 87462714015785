.dashboard-multiplier{
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  .multiplier-container {
    box-sizing: border-box;
    
    width: 100%;
    display: flex;
    align-items: center;
    .element{
      box-sizing: border-box;
      margin: 0px 5px;
      cursor: pointer;
      display: none;
    }
    .multiplier-accordion-container {
      padding: 10px;
      overflow-x: hidden;
      .multiplier-cards{
        display: flex;
        flex-direction: row;
        gap: 40px;
        max-width: 1211px;
      }
    }
    
  }
}
// @media only screen and (max-width: 1366px) {
//   .dashboard-multiplier{
//     .multiplier-cards{
//       max-width: 1211px;
//       gap: 20px;
//     }
//   }
// }

@media  screen and (max-width: 767px){
  .dashboard-multiplier{
    
    .multiplier-container {
      position: relative;
      display: flex;
      align-items: center;
      
      .element {
        display: block;
        position: absolute;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .left{z-index: 1;}
      .right{left: 75%;}
      .multiplier-accordion-container{
        padding: 0px;
        .multiplier-cards{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          gap: 30px;
          padding: 25px 5px;
        }  
      }
      
    }
    
  }
}