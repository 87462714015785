.addtest-step3{
  .form{
    .m-0{
      margin: 0 !important;
    }
    .mt-1{
      margin-top: 1rem !important;
    }
    .form-heading{
      font-size: 1.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
      margin-bottom: 1.5rem;
    }
    label, .addtest-input-label{
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #a4a4a4;
      display: block;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5rem;
    }
    .input-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      .grid{
        box-sizing: border-box;
        margin-top: 2rem;
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
          margin: 0;
        }
      }
      .upload-file{
        max-width: 300px;
        label{
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          text-align: center;
          border-radius: 4px;        
          padding: 0.7rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #ffffff;
          cursor: pointer;
          word-wrap: break-word;
          &.green{
            background-color: #007a04;
          }
          &.blue{
            background-color: #529cf7;
          }
        }
        input{
          visibility: hidden;
          width: 0px;
          height: 0px;
        }
      }
      .addtest-dropdown{
        select{
          box-sizing: border-box;
          width: 100%;
          display: block;
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #505050;
          padding: 0.5rem;
          background-color: #ffffff;
          border: solid 1px #cfcfcf;
          outline: none;
        }
      }
      .addtest-step3-input{
        margin-top: 0px;
        .hide {
          visibility: hidden;
          font-size: 10px;
          padding-top: 0.3rem;
        }
        .show-error {
          color: #fc3232;
          font-size: 10px;
          padding-top: 0.3rem;
        }
        input{
          box-sizing: border-box;
          border-radius: 4px;
          border: solid 1px #cfcfcf;
          background-color: #ffffff;       
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: normal;
          color: #505050;
          width: 100%;
          padding: 0.7rem 0.5rem;
          &:focus{
            outline: none;
          }
          &::placeholder, &::-ms-input-placeholder, &:-ms-input-placeholder {
            color: #a4a4a4;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1470px) {
  .addtest-step3{
    .form{
      .input-grid{
        .upload-file{
          max-width: 200px;
        }
      }
    }
  }
}

@media  screen and (max-width: 786px){
  .addtest-step3{
    .form {
      .input-grid {
        grid-template-columns: 1fr;
        .addtest-dropdown {
          margin: 5px 0px;
          &.grid {
            margin: 5px 0px;
          }
        }
        .addtest-step3-input {
          margin: 5px 0px;
          &.grid {
            margin: 5px 0px;
          }
        }
      }

      .form-heading {
        margin-top: 1.5rem;
      }
    }
  }
}