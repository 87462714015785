.client-dash-test-taken{
  .active-tests, .completed-tests, .paused-tests{
    margin-top: 1rem;
    .cards{
      margin: 0.5rem 0;
    }
  }
  .paused-tests, .completed-tests{
    margin-top: 2rem;
  }
}