.AdminCard {
  background-color: #fff;
  border:var(--glb-border);
  padding: 20px;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  flex-grow: 1;
  max-width: 286px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    .cardContent {
      display: flex;
      flex-direction: column;
      .cardHeading {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: #8898aa;
        padding-bottom: 7px;
      }
      .cardNumber {
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #4d4f5c;
      }
    }
  }
  .cardfooter {
    display: flex;
    padding-top: 1rem;
    .cardPercent {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: left;
      &.green {
        color: #2dce98;
      }
      &.red {
        color: #f53c56;
      }
    }
    .last-month {
      padding-left: 1rem;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: left;
      color: #4d4f5c;
    }
  }
}
