.benefits {
  box-shadow: var(--glb-bg-secondary);
  padding-top: 50px;
  margin: 0 auto;
  padding-bottom: 75px;
  background-color: var(--glb-light);
  .benefits-heading {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .icons-and-description {
    margin: 1px auto;
    width: 1375px;
    text-align: center;
  }
  .icons-and-description-img {
    display: none;
  }
  .icons-placement {
    margin: 1px auto;
    width: 1375px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .styled-border {
    width: 1375px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    margin-top: 23px;
    margin-bottom: 15px;
    .first-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .first-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .second-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .second-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .third-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
    .third-empty-div {
      width: 310px;
      padding: 0;
      margin: 0;
      border-bottom: dashed 5px #123dfb;
    }
    .fourth-circle {
      width: 18px;
      height: 18px;
      border: solid 3px #000000;
      background-color: var(--glb-btn-general);
      border-radius: 50%;
    }
  }
  .icons-content {
    width: 1375px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .content-first {
      width: 250px;
      height: 44px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-second {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-third {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
    .content-fourth {
      width: 250px;
      height: 66px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
  }
  .benefits-grid {
    width: 100%;
    margin: 1px auto 1px auto;
    display: flex;
    flex-wrap: wrap;
    .benefitsSeciton{
      list-style: none;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      .benefitsItem{
        line-height: 4rem;
        img{
          vertical-align: middle;
        }
      }
    }

  }
}

@media only screen and (max-width: 1366px) {
  .benefits {
    .icons-placement {
      width: 100%;
    }
    .styled-border {
      width: 100%;
      .first-empty-div {
        width: 22%;
      }
      .second-empty-div {
        width: 22%;
      }
      .third-empty-div {
        width: 22%;
      }
    }
    .icons-content {
      width: 100%;
    }
    .icons-and-description {
      width: 100%;
    }
    .benefits-grid {
      width: 100%;
      display: grid;
      justify-items: center;
      align-items: center;
      .benefits-box {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .benefits {
    width: 100%;
    .icons-placement {
      width: 100%;
    }
    .styled-border {
      width: 100%;
      .first-empty-div {
        width: 19%;
      }
      .second-empty-div {
        width: 19%;
      }
      .third-empty-div {
        width: 19%;
      }
    }
    .icons-content {
      .content-first {
        width: 100px;
      }
      .content-second {
        width: 100px;
      }
      .content-third {
        width: 100px;
      }
      .content-fourth {
        width: 100px;
      }
    }
    .benefits-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      //grid-template-rows: repeat(6, 1fr);
      .benefitsSeciton{
        width: 95%;
      }
      .benefits-box {
        width: 70%;
      }
    }
    .icons-and-description {
      display: none;
    }
    .icons-and-description-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
