.dashboard-table {
  max-width: 1350px;
  padding-top: 0.6rem;
  th {
    cursor: default;
  }
  .all-projects-taken-table {
    table {
      tr {
        td {
          img {
            &.platform-icon {
              width: 20px;
            }
          }
          &.projectName {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.33;
          }
        }
      }
    }
  }

  &.bug-list {
    .bugName {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.33;
    }
    .assignReviewer{
      padding: 0.25rem;
      border: var(--glb-border);
      font-family: system-ui;
  }
  }

  &.manage-all-earnings-table {
    table {
      tr {
        td {
          &.name {
            color: #155c6e;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            cursor: pointer;
          }
          &.Pending {
            font-weight: 600;
            line-height: 1.33;
            button {
              width: 90px;
              min-width: fit-content;
              color: #ffffff;
              background-color: #df6b1d;
              border: none;
              outline: none;
              padding: 0.25rem;
              border-radius: 3px;
              font-size: 14px;
              letter-spacing: 1px;
              cursor: pointer;
            }
          }
          &.PAID {
            font-weight: 600;
            line-height: 1.33;
            button {
              width: 90px;
              min-width: fit-content;
              color: #ffffff;
              background-color: #079284;
              border: none;
              outline: none;
              padding: 0.25rem;
              border-radius: 3px;
              font-size: 14px;
              letter-spacing: 1px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &.all-projects-table {
    table {
      &.false {
        tr {
          th,
          td {
            &:first-child {
              width: 80%;
            }
          }
        }
      }
      tr {
        td {
          &.projectName {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.33;
          }
        }
      }
    }
  }
  &.all-testers {
    min-height: 450px;
    table {
      tr {
        td {
          &.star {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-top: -5px;
              width: 25px;
              padding-right: 5px;
            }
          }
          &.name {
            color: #155c6e;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
          }
          &.Inactive {
            color: #d20000;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
          }
          &.Active {
            color: #39b21c;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
          }
        }
      }
    }
  }
  &.device-table {
    td {
      &:first-child {
        width: auto;
      }
      &.remove-device {
        cursor: pointer;
        width: max-content;
      }
      &.edit-device {
        cursor: pointer;
        .editIcon {
          width: 15px;
        }
      }
    }
  }
  &.notifications {
    th {
      &:last-child {
        text-align: right;
        box-sizing: border-box;
        padding-right: 5.6rem;
      }
    }
    td {
      &:first-child {
        width: 70%;
      }
      &:last-child {
        width: 30%;
        .linkBtn-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 1.5rem;
        }
      }
    }
  }
  &.client-pricing-table {
    table {
      .pricing-subheading {
        background-color: transparent;
        padding: 0;
        th {
          font-size: 0.8rem;
        }
      }
    }
  }
  &.tester-pricing-table {
    table {
      tr {
        th:first-child {
          width: 50%;
          text-align: left;
        }
        th {
          text-align: left;
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .link {
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  .settings-table {
    width: 517px;
    margin-bottom: 0%;
  }
  .cursor {
    cursor: pointer;
  }
  table {
    width: 100%;
    border-spacing: 0;
  }
  tr {
    width: 100%;
    // box-sizing: border-box;
    padding: 0.5rem 1.2rem;
    th {
      padding: 0.9rem 0;
      font-size: 1.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.8;
      letter-spacing: normal;
      text-align: left;
      color: #565656;
      text-align: center;
      &:first-child {
        //padding: 0 1.2rem;
        text-align: left;
      }
    }
    td {
      padding: 0.5rem 0;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #565656;
      text-align: center;
      &.not-approved {
        color: #dc8400;
      }
      &.approved {
        color: #007a14;
      }
      &.declined {
        color: #b80000;
      }
      &:first-child {
        width: 100%;
        padding: 0.5rem 1.2rem;
        text-align: left;
        display: flex;
        align-items: center;
      }
      &.completed-view-details {
        color: #007a14;
        font-size: 1.1rem;
      }
      .blue-color {
        color: #006ea5;
        text-decoration: none;
      }
      .linkBtn-container {
        display: flex;
        justify-content: center;
        .linkBtn {
          box-sizing: border-box;
          width: 170px;
          display: block;
          font-size: 1.05rem;
          color: #ffffff;
          text-decoration: none;
          text-align: center;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          background-color: #006ea5;
          &.dark {
            background-color: #043852;
            cursor: not-allowed;
          }
        }
      }
      .notification-invit {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        .bugName {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.33;
        }
        .notification-text {
          margin-left: 1.5rem;
          .notification-text-time {
            padding-top: 0.4rem;
            font-size: 0.8rem;
          }
        }
        .img {
          padding: 0.5rem 0.8rem 0.5rem 0;
          height: 40px;
          width: 40px;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &:nth-child(even) {
      background-color: #e9e9e9;
    }
    td[approval="APPROVED"] {
      color: #0a9a00;
    }
    td[approval="PENDING"] {
      color: #ff7001;
    }
    td[approval="REJECTED"] {
      color: #b20000;
    }
  }
}
.deviceslist {
  table {
    tr {
      border-collapse: collapse;
      td {
        border-top: #cfcfcf solid 1px;
      }
      &:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }
}

.project-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

@media only screen and (max-width: 1366px) {
  .dashboard-table {
    // .analysis-table {
    //   width: 100%;
    // }
    tr {
      td {
        &:first-child {
          min-width: 25%;
          width: fit-content;
          padding: 0.5rem 1.2rem;
          text-align: left;
        }
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .dashboard-table {
    .all-projects-taken-table {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 80%;
          }
        }
      }
    }
    &.tester-bank-table {
      table {
        tr {
          th:nth-child(2) {
            width: 70%;
            text-align: left;
            padding-left: 0.8rem;
          }
          td:nth-child(2) {
            text-align: left;
            padding-left: 0.8rem;
          }
          th:nth-child(1),
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(1),
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
    &.badge-table {
      table {
        tr {
          th:nth-child(1) {
            width: 40%;
            text-align: left;
            padding-left: 0.8rem;
          }
          td:nth-child(1) {
            text-align: left;
            padding-left: 0.8rem;
          }
          th:nth-child(2) {
            width: 20%;
            text-align: left;
            padding-left: 0.8rem;
          }
          td:nth-child(2) {
            text-align: left;
            padding-left: 0.8rem;
          }
        }
      }
    }
    &.all-testers {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4),
          th:nth-child(5),
          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5) {
            display: none;
            visibility: collapse;
          }
          th:nth-child(1),
          td:nth-child(1) {
            width: 50%;
          }
          th:nth-child(2),
          td:nth-child(2) {
            width: 25%;
          }
          th:nth-child(3),
          td:nth-child(3) {
            width: 25%;
          }
        }
      }
    }
    &.manage-all-earnings-table {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          th:nth-child(1),
          td:nth-child(1) {
            width: 50%;
            text-align: left;
          }
        }
      }
    }
    &.all-projects-table {
      table {
        &.true {
          tr {
            th:nth-child(3) {
              display: none;
              visibility: collapse;
            }
            td:nth-child(3) {
              display: none;
              visibility: collapse;
            }
          }
        }
        &.false,
        &.true {
          tr {
            th,
            td {
              &:first-child {
                width: 70%;
              }
            }
          }
        }
      }
    }
    &.initilized-transfer-table {
      table {
        tr {
          th:last-child {
            display: none;
            visibility: collapse;
          }
          td:last-child {
            display: none;
            visibility: collapse;
          }
          td {
            &.PAID {
              color: #333;
            }
          }
        }
      }
    }

    &.completed-test-table {
      table {
        tr {
          th:nth-child(1) {
            width: 50%;
          }
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
    &.deviceslist {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
    &.myearning-table {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:first-child {
            width: 60%;
          }
        }
      }
    }
    &.lifetime-earning-table {
      table {
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
    &.device-table {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(4),
          th:nth-child(5),
          th:nth-child(7),
          th:nth-child(8) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5),
          td:nth-child(7),
          td:nth-child(8) {
            display: none;
            visibility: collapse;
          }
          // td{
          //   &:first-child{
          //     width: auto;
          //   }
          // }
        }
      }
    }

    &.live-projects {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(4),
          th:nth-child(5),
          th:nth-child(6) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5),
          td:nth-child(6) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 80%;
          }
          td:first-child {
            width: 80%;
          }
        }
      }
    }

    &.bug-detected-table {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 70%;
          }
          td:first-child {
            width: 70%;
          }
        }
      }
    }

    &.bug-list {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(5) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(5) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 70%;
          }
          td:first-child {
            width: 70%;
          }
        }
      }
    }
    .analysis-table {
      table {
        tr {
          th {
            font-size: 0.875rem;
          }
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          td {
            font-size: 0.8125rem;
            .notification-invit {
              margin-left: 0px;
            }
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 70%;
          }
          td:first-child {
            width: 70%;
          }
        }
      }
    }

    &.active-test-table {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(3) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3) {
            display: none;
            visibility: collapse;
          }
          th:first-child {
            width: 70%;
          }
          td:first-child {
            width: 70%;
          }
        }
      }
    }

    &.pause-test-table {
      table {
        tr {
          th:first-child {
            width: 70%;
          }
          td:first-child {
            width: 70%;
          }
        }
      }
    }

    &.notifications {
      table {
        tr {
          align-items: center;
          justify-content: center;
        }
        td {
          .notification-invit {
            .notification-text {
              display: none;
            }
          }
          .linkBtn-container {
            .linkBtn {
              width: max-content;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  &.device-table {
    table {
      tr {
        th:nth-child(2),
        th:nth-child(5),
        th:nth-child(7),
        th:nth-child(8) {
          display: none;
          visibility: collapse;
        }
        td:nth-child(2),
        td:nth-child(5),
        td:nth-child(7),
        td:nth-child(8) {
          display: none;
          visibility: collapse;
        }
        // td{
        //   &:first-child{
        //     width: auto;
        //   }
        // }
      }
    }
  }
}
