.singleTestimonials {
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  height: 240px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: var(--glb-shadow);
  padding: 20px;
  position: relative;
  word-wrap: break-word;
  .testimonials-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 180px;
    margin-bottom: 20px;
    .testerNames {
      display: flex;
      align-items: center;
      font-weight: bold;
      gap: 9px;
      .testimonialTesterImage {
        vertical-align: middle;
        border-radius: 50%;
      }
    }
    .testimonial-statuss {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px;
    }
  }
  .testimonials-div {
    margin-bottom: 30px;
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .singleTestimonials {
    height: 270px;
  }
}
