.dashboard-client-addtest {
  .addtest-steps {
    .form {
      width: 100%;
      .label {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.23;
        text-align: left;
        color: #606060;
        padding-top: 22px;
        padding-bottom: 10px;
      }
      .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        input {
          padding: 13px 39.4px 13px 20.6px;
          border-radius: 4px;
          border: solid 1px #cfcfcf;
          background-color: #f3f3f3;
          outline: none;
          width: 70%;
        }
        button {
          padding: 13px 10px;
          border-radius: 4px;
          background-color: var(--glb-btn-general);
          width: 30%;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.29;
          text-align: center;
          color: #ffffff;
          outline: none;
          border: none;
        }
      }
      .search-response {
        background-color: #fff;
        width: 100%;
        padding: 1rem;
        box-shadow: var(--glb-shadow);
        position: relative;
        border-radius: 15px;
        margin-top: 1rem;
        display: none;
        .list-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
          padding: 0.5rem;
          border-bottom: solid 1px #333;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          .appName {
            color: #233137;
          }
          &:hover {
            background-color: rgb(218, 216, 216);
          }
        }
      }
      input {
        padding: 13px 39.4px 13px 20.6px;
        border-radius: 4px;
        border: solid 1px #cfcfcf;
        background-color: #f3f3f3;
        outline: none;
        width: 90%;
      }
      textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 119px;
        background-color: #f3f3f3;
        border: solid 1px #cfcfcf;
        outline: none;
        padding: 0.5rem;
        &:focus {
          outline: none;
        }
      }

      .logo-uploader-auto {
        display: flex;
        width: 100%;
        gap: 1rem;
        .appLogo {
          width: 100px;
          height: 100px;
        }
        .logo-uploader-input-auto {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          gap: 1rem;
          justify-content: flex-start;
          flex-direction: column;
          label {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: 1px dotted #cfcfcf;
            text-align: center;
            padding: 0.5rem 2rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            box-sizing: border-box;
            color: #008080;
            margin: 0;
            cursor: pointer;
          }
          input {
            display: none;
            visibility: hidden;
          }
        }
      }

      .addtest-dropdown {
        width: 100%;
        margin-bottom: 1.2rem;
        select {
          box-sizing: border-box;
          width: 100%;
          display: block;
          font-size: 0.8rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #505050;
          padding: 0.5rem;
          background-color: #f3f3f3;
          border: solid 1px #cfcfcf;
          outline: none;
        }
      }
    }

    .back-btn {
      font-size: 1.1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #123dfb;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
}
.blue-color {
  color: #006ea5;
  text-decoration: none;
}
