.client-side-home {
  max-width: 1260px;
  .heading-client-home {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #233137;
    .platform-logo {
      padding-right: 0.7rem;
      height: 35px;
      margin: 0 1rem;
      img {
        display: block;
        height: 100%;
      }
    }
  }
  .overall-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1.4rem;

    .client-home-header {
      display: flex;
      align-items: center;
    }
    .project-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .project-button {
        display: block;
        margin-right: 0.5rem;
        &.pointer {
          cursor: pointer;
        }
        .btn {
          cursor: pointer;
          padding: 0.5rem 1.5rem;
          border-radius: 3px;
          border: none;
          outline: none;
          color: #fff;
          display: flex;
          align-items: center;
          .btn-icon {
            width: 12px;
            height: 12px;
            padding-right: 5px;
          }
        }
        .CANCELED {
          background-color: #fb3a3a;
        }
        .PAUSED {
          background-color: #079284;
        }
        .MARK {
          background-color: #00b812;
        }
        .LIVE {
          background-color: #00b812;
        }
        .SCHEDULED,
        .RESCHEDULE {
          background-color: #012665;
        }
        .EDIT {
          background-color: #00a3ff;
        }
      }
    }
    .project-status {
      .label {
        margin-bottom: 0.3rem;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
        display: none;
      }

      .project-button {
        display: block;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        &.pointer {
          cursor: pointer;
        }
        .btn {
          padding: 0.4rem 1rem;
          border-radius: 50px;
          border: none;
          outline: none;
          color: #fff;
          display: flex;
          align-items: center;
          .btn-icon {
            width: 12px;
            height: 12px;
            padding-right: 5px;
          }
          &.CANCELED {
            background-color: #fb3a3a;
          }
          &.LIVE {
            background-color: #00b812;
          }
          &.PAUSED {
            background-color: #079284;
          }
          &.COMPLETED {
            background-color: #df6b1d;
          }
          &.SCHEDULED,
          &.RESCHEDULE {
            background-color: #012665;
          }
        }
      }
    }
  }
  .client-home-heading {
    font-weight: lighter;
  }
  .client-home-pie-data {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    font-size: 18px;
    // display: flex;
    // gap: 0px;
    display: grid;
    // grid-template-areas:
    // "pi-chart repeated critical minor"
    // "pi-chart major crash low"
    // "pi-chart used-hours left-hours total-bugs";
    grid-template-areas:
      "pi-chart pi-chart pi-chart"
      "used-hours left-hours total-bugs";
    // grid-template-areas:
    // "pi-chart pi-chart pi-chart pi-chart"
    // "used-hours left-hours total-bugs project-health";
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    justify-content: center;
    align-items: center;
    .client-home-pie {
      // flex: 2;
      height: 100%;
      width: 700px;
    }
    // .client-states{
    // flex: 3;
    // margin-left: 1.5rem;

    .states {
      display: flex;
      align-items: center;
      flex-direction: row;
      .number {
        font-size: 1.1rem;
        width: 45px;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--glb-txt-primary);
        &.blue {
          background-color: #00a3ff;
        }
        .project-actions {
            display: flex;
            align-items: center;
            justify-content: center;

            .project-button {
                display: block;
                margin-right: 0.5rem;
                &.pointer{
                  cursor: pointer;
                }
                .btn{
                  cursor: pointer;
                  padding: 0.5rem 1.5rem;
                  border-radius: 3px;
                  border: none;
                  outline: none;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  .btn-icon {
                    width: 12px;
                    height: 12px;
                    padding-right: 5px;
                  }
                }
                .CANCELED{
                  background-color: #fb3a3a;
                }
                .PAUSED {
                  background-color: #079284;
                }
                .MARK {
                    background-color: #00b812;
                }
                .PUBLISH {
                    background-color: #00b812;
                }
                .SCHEDULED, .RESCHEDULE {
                  background-color: #012665;
                }
                .EDIT {
                    background-color: #00a3ff;
                }
                
              }
        }
        .project-status{
            .label{
                margin-bottom: 0.3rem;
                font-size: 10px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: left;
                color: #a4a4a4;
                display: none;
            }

            .project-button {
                display: block;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                &.pointer{
                  cursor: pointer;
                }
                .btn{
                  padding: 0.4rem 1rem;
                  border-radius: 50px;
                  border: none;
                  outline: none;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  .btn-icon {
                    width: 12px;
                    height: 12px;
                    padding-right: 5px;
                }
                    &.CANCELED  {
                        background-color: #fb3a3a;
                    }
                    &.PUBLISH {
                        background-color: #00b812;
                    }    
                    &.PAUSED {
                        background-color: #079284;
                    }
                    &.COMPLETED {
                        background-color: #df6b1d;
                    }
                    &.SCHEDULED, &.RESCHEDULE {
                        background-color: #012665;
                    }
                }
                
            }
        }
        &.red {
          background-color: #ff4900;
        }
        &.orange {
          background-color: #ff7b00;
        }
        &.green {
          background-color: #58c70e;
        }
      }
      .states-desc {
        color: #505050;
        font-size: 1.1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        margin-left: 0.5rem;
      }
    }
    .score {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #a4a4a4;
      }
      .overall-div {
        display: flex;
        width: 90%;
        margin-top: 0.3rem;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .outer-div {
          width: 100%;
          height: 27px;
          box-sizing: border-box;
          background-color: rgb(177, 176, 176);
          .inner-div {
            width: 85%;
            height: 100%;
            box-sizing: border-box;
            background-color: rgb(88, 199, 14);
          }
        }
        .percentage {
          font-size: 1.2rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #5ac21c;
          margin-left: 1rem;
        }
      }
    }

    .test-data {
      .label {
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #a4a4a4;
        text-align: center;
      }
      .data {
        margin-top: 0.2rem;
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #232323;
        text-align: center;
      }
    }
    // }
    .client-home-bug-responsive {
      display: none;
    }
  }
  .client-home-bug-table {
    margin: 2rem 0;
    .client-home-bugs-heading {
      display: flex;
      align-items: center;
      .client-side-home-bugs-download {
        font-size: 0.85rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        border-radius: 4px;
        background-color: #6076fa;
        display: flex;
        justify-content: center;
        width: 121px;
        height: 28px;
        align-items: center;
        margin-left: 2rem;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .client-side-home {
    .overall-header {
      flex-direction: column;
      align-items: flex-start;
      .client-home-header {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1.1rem;
        .project-status {
          margin-top: 0.2rem;
          .label {
            display: block;
          }
          .project-button {
            margin-left: 0px;
            .btn {
              font-size: 12px;
              padding: 6px 14px 6px 14.4px;
            }
          }
        }
      }
      .project-actions {
        width: 100%;
        justify-content: space-between;
        .project-button {
          .btn {
            font-size: 12px;
            padding: 6px 14px 6px 14.4px;
          }
        }
      }
    }
    .client-home-pie-data {
      .client-home-pie {
        width: 300px;
      }
    }
  }
}
