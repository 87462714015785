.text-bug-list{
  margin: 0.7rem 0;
  .BackBtnContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &.FormFillUp-backbtn {
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #123dfb;
      background-color: transparent;
      margin-bottom: 10px;
      cursor: pointer;
      width: max-content;
    }
  }
}