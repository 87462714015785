.manageBadgesContainer {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .createBadge {
      display: block;
      min-width: fit-content;
      border: none;
      outline: none;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1rem 3rem;
      border-color: #123dfb;
      background-color: var(--glb-btn-general);
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
      cursor: pointer;
    }
  }
  .badgescardContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .manageBadgesContainer {
    .header {
      .createBadge {
        padding: 0.5rem 0.5rem;
      }
    }
  }
}
