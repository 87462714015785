.device-farm {
  box-sizing: border-box;
  padding-top: 100px;
  box-shadow: var(--glb-shadow);
}
@media only screen and (max-width: 1440px) {
  .device-farm {
    padding-top: 80px;
  }
}
