.client-accordian {
  max-width: 1100px;

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    .search-client {
      height: 35px;
      width: 200px;
      outline: none;
      border: none;
      border-bottom: 1px solid #505050;
      background: none;
    }
    .active-clients {
      padding: 12px 20px;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      margin-bottom: 1.5rem;
      margin-left: 1rem;
      &.blue {
        background-color: #1c76b2;
      }
      &.orange {
        background-color: #fd6529;
      }
    }
  }

  .clients-mainAccordian {
    width: 100%;
    max-width: 1100px;
    .clients-list-item {
      border-radius: 4px;
      border: solid 1px #d4dfff;
      background-color: #ffffff;
      box-shadow: var(--glb-shadow);
      padding: 10px 18px 9px 21px;
      margin-bottom: 30px;
      .clients-list-item-title {
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          margin: 5px 0px;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.22;
          letter-spacing: normal;
          text-align: left;
          color: #505050;
        }
        .clients-accordian-header {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2rem;
          .clients-accordian-client-details {
            .clients-accordian-header-name {
              min-width: 150px;
              line-height: 1.3;
            }
            .clients-accordian-client-website {
              min-width: 150px;
              font-size: 0.8rem;
            }
          }
          .clients-accordian-header-active {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            .clients-accordian-header-status {
              padding: 8px;
              border-radius: 50%;
              margin-right: -1.2rem;
              &.Active {
                background-color: #39b21c;
              }
              &.Inactive {
                background-color: #d20000;
              }
            }
            .accordian-client-status {
              min-width: 50px;
            }
            .change-status {
              font-size: 14px;
              color: #247dd2;
              cursor: pointer;
            }
          }
        }
        span {
          background-color: #d4dfff;
          border-radius: 50%;
          width: 36px;
          height: 36px;
        }
      }
      .accordianContent {
        .row {
          display: flex;
          padding: 10px 0px;
          table {
            tr {
              td {
                img {
                  &.platform-icon {
                    width: 20px;
                  }
                }
                &.Active {
                  color: #0a9a00;
                }
                &.Inactive {
                  color: #e50000;
                }
                &.PUBLISH {
                  color: #00b812;
                  font-weight: 600;
                }
                &.PAUSED {
                  color: #079284;
                  font-weight: 600;
                }
                &.COMPLETED {
                  color: #dc8400;
                  font-weight: 600;
                }
                &.DRAFT {
                  color: #012665;
                  font-weight: 600;
                }
                &.CANCELED {
                  color: #fb3a3a;
                  font-weight: 600;
                }
                &.mark-as-complete {
                  font-weight: 600;
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .accordianContent {
    .row {
      table {
        tr {
          th:first-child {
            width: 50%;
          }
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(5),
          th:nth-child(7) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(5),
          td:nth-child(7) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .accordianContent {
    .row {
      table {
        tr {
          th:first-child {
            width: 50%;
          }
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(4),
          th:nth-child(5) {
            display: none;
            visibility: collapse;
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5) {
            display: none;
            visibility: collapse;
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .client-accordian {
    .buttons-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      margin-bottom: 1rem;
      .search-client {
        width: 100%;
      }
      .active-clients {
        margin-left: 0rem;
        margin-bottom: 0rem;
      }
    }
  }
}

@media (max-width: 650px) {
  .client-accordian {
    .clients-mainAccordian {
      .clients-list-item {
        .clients-list-item-title {
          .clients-accordian-header {
            flex-direction: column;
            gap: 0px;
          }
        }
      }
    }
  }
}
