.ourClients {
  //background-color: #f7f7f7;
  opacity: 0.9;
  
  h4 {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .clientsBanner {
    width: 100%;
    overflow: hidden;
.clientsList{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 200px;
  align-items: center;
  padding-left: 100%;
  transition: 2s;
  animation: infiniteScroll 15s linear infinite;
  white-space: nowrap;
    .image-container {
      padding: 0.5rem;
      width: 210px;
      height: 110px;
      display: flex;
      align-items: center;
    }
  }
  }
}

@keyframes infiniteScroll {
  100% {
    transform: translate(-100%, 0);
  }
}

@media screen and (max-width:786px){
  .ourClients{
    .clientsBanner{
      .clientsList{
        width: fit-content;
        animation: infiniteScroll 10s linear infinite;
      }
    }
  }
  
}