.user-testing {
  box-shadow: var(--glb-bg-secondary);
  padding-top: 70px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .user-testing-heading {
    margin: 0;
    font-size: 34px;
    padding: 0 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .user-testing-content {
    max-width: 1275px;
    padding: 0 1.5rem;
    margin: 29px auto 50px auto;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #5a5a5a;
  }
  .user-testing-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 53px;
    padding: 3rem;
    .user-testing-box {
      max-width: 450px;
      min-height: 267px;
      box-sizing: border-box;
      padding: 35px 25px 35px 25px;
      text-align: center;
      border-radius: 4px;
      box-shadow: var(--glb-shadow);
      background-color: #ffffff;
      .box-heading {
        margin: 0;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
      }
      .box-content {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: center;
        color: #5a5a5a;
      }
    }
  }
}
@media (max-width: 786px) {
  .user-testing {
    .user-testing-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
