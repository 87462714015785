.contactUs{
    width: 100%;
    display: flex;
    gap: 30px;
    position: relative;
    color: var(--glb-txt-primary);
    padding: 80px;
    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  // background: url("../../assets/images/banner-bg.png") no-repeat center center/cover;
  h1 {
    text-align: center;
    margin: 0;
    margin-top: 50px;
    font-size: 36px;
    padding-bottom: 0.5rem;
  }
  p {
    margin-top: 5px;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    // color: #ffffff;
  }

.actionSection{
    display: flex;
    width: 100%;
    gap: 50px;
    align-items: center;
    &>div{
        width: 100%;
      }
    .contactUsForm{
        background: #fff;
        padding: 25px;
        box-shadow: var(--glb-shadow);
        position: relative;
        width: 100%;
        h2{
          font-size: 28px;
          text-align: center;
          color: #121212;
        }
        fieldset {
          border: medium none !important;
          margin: 0 0 10px;
          min-width: 100%;
          padding: 0;
          width: 100%;
          label{
            transition: ease 0.5s;
          }
          .contactFormLabel{
            font-size: 12px;
            color: #1f1f1f;
            margin-bottom: 5px;
          }
          input[type="text"],
          input[type="email"],input[type="tel"],textarea{
          width: 100%;
          border: 1px solid #ccc;
          background: #FFF;
          margin: 0 0 5px;
          padding: 10px;
          &::placeholder{
            color: #aaa;
          }
          &:focus{
            outline: #1f1f1f;
          }
          }
          textarea{
            height:200px;
          }
        }
    }
  
    .contactUsDetails{
      .ourAddress{
        .infoTitle{
         text-align: left;
         margin-bottom: 4px;
        }
        .infoContent{
          text-align: left;
          font-size: 18px;
        }
      }
    }
}
}

@media screen and (max-width:786px) {
    .contactUs{
        padding: 20px;
        h1{
            margin-top: 86px;
        }
        .actionSection{
            flex-wrap: wrap;
        }
    }
    
}