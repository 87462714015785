.skill-sets {
  width: 333px;
  box-sizing: border-box;
  margin-top: 40px;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: var(--glb-shadow);
  background-color: #ffffff;
  .skill-label-clear {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 0.3rem 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #d8d8d8;
    .skill-label {
      font-size: 1rem;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
    }
    .skill-clear {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      svg {
        fill: #cbcbcb;
        height: 22px;
        width: 22px;
        transition: all 0.3s;
      }
      &:hover {
        svg {
          fill: #9b9b9b;
        }
      }
    }
  }
  .star-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.3rem 0.4rem;
    padding-top: 1rem;
    .single-star {
      svg {
        width: 36px;
        height: 36px;
        fill: #dedede;
        transition: all 0.1s;
      }
      &.selected {
        svg {
          fill: #ffc107;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .skill-sets {
    margin-top: 10px;
  }
}
