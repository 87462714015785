.client-pricing-container {
  .cards-container-pricing {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 25px;
  }
}

@media (max-width: 722px) {
  .client-pricing-container {
    .cards-container-pricing {
      justify-content: center;
    }
  }
}