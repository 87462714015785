.home-services {
  .services-heading {
    padding-top: 90px;
    padding-bottom: 70px;
    // background: url("../../assets/images/banner-bg.png") no-repeat center center/cover;
    background-color: var(--glb-light);
    box-shadow: var(--glb-shadow);
    .services-heading-content {
      max-width: 1324px;
      padding: 0 3rem;
      margin: 80px auto 0 auto;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      // color: #ffffff;
      .services-heading-highlight {
        color: #ffcc00;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .home-services {
    .services-heading {
      padding-top: 73px;
    }
  }
}
