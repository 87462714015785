.cards-grid-02 {
  // background-image: url("../../../assets/images/banner-bg.png");
  background: var(--glb-bg-secondary);
  padding-bottom: 2em;
  h4 {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    // color: #ffffff;
  }
  .custom-container {
    .custom-row-01 {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .image-container {
    padding-bottom: 1em;
    img {
      height: 240px;
      width: 240px;
    }
  }
}

@media only screen and (max-width: 786px) {
  .cards-grid-02 {
    .image-container {
      img {
        min-height: 230px;
        max-height: 230px;
        min-width: 230px;
        max-width: 230px;
        box-sizing: border-box;
        margin: 20px;
      }
    }
  }
}
@media screen and (max-width: 786px) {
  .cards-grid-02 {
    .custom-container {
      .custom-row-01 {
        row-gap: 80px;
      }
    }
  }
}
