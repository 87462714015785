.addtest-cart {
  margin-top: 100px;
  padding: 2rem 10px;
  padding-top: 1.5rem;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: var(--glb-shadow);
  position: relative;
  .cart-icon {
    position: absolute;
    border-radius: 4px;
    background-color: var(--glb-btn-general);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 71px;
    width: 71px;
    top: -20px;
    left: 35px;
    .img {
      width: 50px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .cart-heading {
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    margin-left: 110px;
    color: #565656;
    margin-bottom: 2rem;
  }
  .align-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .service {
      .heading {
        font-size: 0.95rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #444444;
      }
      .sub-heading {
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        min-height: 19px;
      }
    }
    .amount {
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: right;
      color: #707070;
    }
  }

  .list-heading {
    margin: 1rem 0;
    background-color: #ebebeb;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #505050;
    padding: 0.5rem 1rem;
  }

  .space-below {
    margin-bottom: 1rem;
  }

  .seprator {
    padding-bottom: 1rem;
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 1.3rem;
  }

  .sub-total-addtester {
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #565656;
  }
}

@media screen and (max-width: 786px) {
  .addtest-cart {
    margin-top: 50px;
  }
}
