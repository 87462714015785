.cards-grid-01 {
  h4 {
    font-size: 36px;
    color: var(--glb-dark-blue); //var(--heading-dark);
    line-height: 44px;
  }
  .custom-container {
    .home-custom-row-01 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 25px;
      flex-wrap: wrap;
      .item--container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 2rem;
        text-align: center;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: var(--glb-shadow);
        border: var(--glb-border);
        span {
          display: block;
          font-weight: bold;
          color: #5a5a5a;
          font-size: 1rem;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          letter-spacing: normal;
          text-align: center;
        }
        .whyQantily_heading {
          margin: 0;
          margin-top: 20px;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: var(--glb-dark-blue);
        }
        .whyQantily_description {
          font-size: 17px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: normal;
          text-align: center;
          color: #5a5a5a;
          padding: 0.5rem 2rem;
        }
      }
    }
    .home-custom-row-001 {
      // display: grid;
      // grid-template-columns: repeat(8, 1fr);
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
    }
  }
  .image-container {
    .floating-cards {
      background: #fff;
      //box-shadow: var(--glb-shadow);
      padding: 10px;
      border-radius: 50%;

      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 60px;
    }
  }
  .item--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      display: block;
      font-weight: bold;
      color: #5a5a5a;
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      margin-top: 0.5rem;
      letter-spacing: normal;
      text-align: center;
    }
  }
  .description-container {
    text-align: center;
    padding-bottom: 2.5em;
    span {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #5a5a5a;
    }
  }
  .btn-container {
    padding-top: 4em;
    padding-bottom: 2em;
  }
}

@media only screen and (max-width: 1600px) {
  .cards-grid-01 {
    .image-container {
      .floating-cards {
        background: #fff;
        //box-shadow: var(--glb-shadow);
        padding: 15px;
        border-radius: 50%;
        margin-bottom: 10px;
        margin: 25px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (max-width: 1232px) {
  .cards-grid-01 {
    .custom-container {
      .home-custom-row-001 {
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
@media (max-width: 1024px) {
  .cards-grid-01 {
    .custom-container {
      .home-custom-row-01 {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media (max-width: 786px) {
  .cards-grid-01 {
    .custom-container {
      .home-custom-row-01 {
        gap: 20px;
      }
      .home-custom-row-001 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.7rem;
      }
    }
  }
}
@media (max-width: 528px) {
  .cards-grid-01 {
    .custom-container {
      .home-custom-row-01 {
        gap: 20px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
