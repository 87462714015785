.client-dash-devices{
  margin-top: 15px;
  .devices{
    margin-top: 1rem;
    .cards{
      margin: 0.8rem 0;
    }
  }
}

@media (max-width: 1466px){
  .client-dash-devices{
    margin-top: inherit;
  }
}

@media screen and (max-width: 786px){
  .client-dash-devices{
    width: 100%;
    margin-top: 2rem;
  }
}