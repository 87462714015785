.dashboard-sub-heading{
  display: flex;
  h2{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: left;
    color: #233137;
    margin: 1rem 0;
  }
  button{
    display: block;
    border: none;
    width: 132px;
    height: 35px;
    margin: 28px 58px 21px 20px;
    border-radius: 7px;
    background-color: #6076fa;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    &:hover{
      background-color: #6076fa;
    }
    &:focus{
      outline: none;
    }
  }
}