.none {
  display: none;
}

.declineBug-modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 99;
  .declineBug-modal {
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2rem 1.9rem;
    box-sizing: border-box;
    min-width: 700px;
    .close-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .close {
        width: max-content;
        cursor: pointer;
      }
    }
    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
        margin: 0;
        padding-bottom: 18px;
      }
      h4 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: #393939;
      }
    }

    .body-container {
      padding-bottom: 1.5rem;
      .body {
        .declinePopUpContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .declinePopUp {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            background-color: #fff;
            padding: 2rem;
            border-radius: 0.5rem;
            width: 550px;
            & ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }
            .selectButtons {
              display: flex;
              justify-content: flex-end;
              gap: 25px;
              margin-bottom: 10px;
              .search-testers {
                outline: none;
                border: none;
                border-bottom: solid 1px black;
              }
              .selectAll {
                background-color: #4fb91e;
                padding: 12px 20px;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
                width: 150px;
              }
              .deSelectAll {
                background-color: #ff3400;
                padding: 12px 20px;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
                width: 150px;
              }
            }
            .attachedMessage {
              margin-top: 18px;
              border: none;
              border-bottom: 2px solid grey;
              padding: 12px;
              font-size: 20px;
              &:focus {
                outline: none;
                font-size: 20px;
              }
            }
            .testerList {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 40px;
              h3 {
                margin: 0;
                color: black;
                font-weight: 500;
              }
            }

            .search-field {
              border: 0;
              border-bottom: 1px solid grey;
              font-size: 16px;
              padding-bottom: 8px;
              &:focus {
                outline: 0;
              }
            }
            .declinePopUpReason {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #505050;
            }
            .declinePopUp-input {
              width: 100%;
              background-color: #e6e6e6;
              border: none;
              color: #505050;
              outline: none;
              line-height: 2;
              font-size: 1rem;
            }
            .declinePopUpButtons {
              margin-top: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              button {
                width: 200px;
                height: 41px;
                background-color:var(--glb-blue);
                cursor: pointer;
                font-size: 1rem;
                font-weight: 700;
                border-radius: 2px;
                outline: none;
                border: none;
                padding: 0;
                margin: 0;
                &.no {
                  color: #505050;
                  border: solid 1px #ff3400;
                }
                &.yes {
                  color: #fff;
                  background-color: #ff3400;
                }
              }
              .sendInvite {
                background-color: #4fb91e;
                padding: 12px 20px;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
              }
            }
            .deivce-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .device {
                margin: 0;
                color: black;
                font-weight: 500;
              }
              .mail-button {
                color: #123dfb;
                margin: 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .declineBug-modal-container {
    position: fixed;
    width: 95%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 20%;
    overflow-y: scroll;
    //padding: 150px 0px;
    .declineBug-modal {
      min-width: 300px;
      height: auto;
      width: 99%;
      margin-top: 35vh;
      margin-bottom: 5vh;
      padding: 15px 15px 15px 15px;
      border-radius: 25px;
    }
  }
}
