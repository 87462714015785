.platform-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #e5e5e5;
  width: 193px;
  height: 193px;
  cursor: pointer;
  .platform-img {
    height: 95px;
    color: #002a7e;
  }
  .platform-name {
    padding-top: 25px;
    color: #505050;
  }

  &.active {
    background-color: #002a7e;
    .platform-img {
      color: #fff;
    }
    .platform-name {
      color: #fff;
    }
  }
}