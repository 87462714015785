.tester-completed-project-bug {
  background-color: var(--glb-bg-secondary);
  margin-left: -32px;
  margin-top: -32px;
  padding-left: 32px;
  padding-top: 32px;

  .BackBtnContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1rem;
    &.FormFillUp-backbtn {
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #123dfb;
      background-color: transparent;
      margin-bottom: 10px;
      cursor: pointer;
      width: max-content;
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #505050;
  }
  .tester-completed-test-span {
    font-weight: lighter;
  }

  .status_container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 1rem;
    .label {
      font-weight: 600;
      font-size: 1rem;
    }
    .tester-completed-test-status {
      width: max-content;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      background-color: #ff3400;
      color: var(--glb-txt-primary);
      cursor: context-menu;
      &.APPROVED {
        background-color: #0a9a00;
      }
      &.PENDING {
        background-color: #e88301;
      }
    }
  }
  .reviewer-action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    margin-bottom: 1.5rem;
    margin-top: 0.6rem;
    .reviewer-btn {
      width: 200px;
      height: 41px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 700;
      border-radius: 5px;
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      &.yes {
        background-color: #0a9a00;
      }
      &.no {
        background-color: #b20000;
      }
    }
  }

  .tester-completed-test-summary {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .tester-completed-test-severity {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .tester-completed-test-oem {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .tester-completed-test-description {
    max-width: 900px;
    min-height: 188px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .tester-completed-test-platform {
    max-width: 900px;
    height: 41px;
    margin: 12px 0 40px;
    padding: 20.5px 312.4px 0px 20.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  button {
    max-width: 502px;
    margin: 0px 0 20px;
    padding: 12px 184.4px 12px 184.5px;
    border-radius: 4px;
    border: dashed 1px #cfcfcf;
    color: #008080;
    margin: 12px 0 40px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  #getFile {
    display: none;
  }

  .reviewer-summary,
  .reviewer-component,
  .reviewer-dropbox {
    max-width: 900px;
    width: 90%;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
    border: none;
    outline: none;
  }
  .reviewer-textarea {
    max-width: 900px;
    width: 90%;
    height: 150px;
    margin: 12px 0px 20px;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
    border: none;
    outline: none;
  }
  .reviewer-update-btn {
    width: 161px;
    height: 41px;
    margin: 12px 0px 25px;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #e88301;
    color: var(--glb-txt-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 786px) {
  .tester-completed-project-bug {
    .reviewer-action {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      padding: 14px;
    }
  }
}
