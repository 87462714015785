.additionalinfo {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: var(--glb-bg-secondary);
  .bold {
    font-weight: bold;
  }
  .home_additionalinfo_flex_first {
    display: flex;
    width: 100%;
    .home_additionalinfo_first_div {
      flex: 1;
      h4 {
        //max-width: 527px;
        height: 44px;
        margin: 50px 30px 30px 50px;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
      }
      .howItWorks{
        display: flex;
        justify-content: space-around;
        .howItWorks-item{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h4{
            margin: 30px 30px;
          }
          p{
            width: 60%;
            margin: auto;
            text-align: center;
          }
        }
      }
    }
    .home_additionalinfo_second_div {
      flex: 1;
      max-width: 820px;
      height: max-content;
      margin: 30px 0 30px 50px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #5a5a5a;
    }
    .home_additionalinfo_third_div {
      flex: 1;
      width: 333px;
      //height: 70px;
      margin: 30px auto;

      // padding: 35px 83px 0px 84px;
      // border-radius: 4px;
      // background-color: var(--glb-btn-general);
      // font-size: 28px;
      // font-weight: 600;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: 1.21;
      // letter-spacing: normal;
      // text-align: center;
      // color: #ffffff;
      // cursor: pointer;
    }
    .home_additionalinfo_fourth_div {
      flex: 1;
      height: 401px;
      margin: 25px;
      img {
        display: block;
        width: 100%;
        width: 100%;
      }
    }
  }
  .home_additionalinfo_flex_second {
    display: flex;
    width: 100%;
    // .home_additionalinfo_one_div {
    //   flex: 1;
    //   height: 401px;
    //   margin: 0px 130px 120px 50px;
    //   img {
    //     display: block;
    //     width: 100%;
    //     width: 100%;
    //   }
    // }
    .home_additionalinfo_two_div {
      flex: 1;
      h4 {
        //max-width: 527px;
        height: 44px;
        margin: 50px 30px 30px 50px;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: var(--glb-dark-blue);
      }

      .howItWorks{
        display: flex;
        justify-content: space-around;
        .howItWorks-item{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h4{
            margin: 0.25rem 0.25rem;
          }
          p{
            width: 40%;
            margin: auto;
            text-align: center;
          }
        }
      }
    }
    .home_additionalinfo_three_div {
      flex: 1;
      max-width: 820px;
      height: max-content;
      margin: 30px 0px 30px 0;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #5a5a5a;
    }
    .home_additionalinfo_four_div {
      flex: 1;
      width: 333px;
      //height: 70px;
      margin: 30px auto;

      // height: 70px;
      // margin: 30px 0px 146px 0;
      // padding: 35px 83px 0px 84px;
      // border-radius: 4px;
      // background-color: var(--glb-btn-general);
      // font-size: 28px;
      // font-weight: 600;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: 1.21;
      // letter-spacing: normal;
      // text-align: center;
      // color: #ffffff;
      // cursor: pointer;
    }
  }
  .additionalinfo_flex_third {
    flex: 1;
    display: flex;
    max-width: 1460px;
    margin: 0px 115px 120px 115px;
    gap: 70px;
    .flex_first_part {
      width: 439px;
      height: 290px;
      padding: 35px 49px 0px 25px;
      border-top: solid 10px;
      border-radius: 4px;
      box-shadow: var(--glb-shadow);
      background-image: linear-gradient(to bottom, #fefefe, #eceeed);
      h4 {
        cursor: pointer;
      }
    }
    .pay_as_u_go {
      margin-top: 13.9px;
      margin-bottom: 12.6px;
      padding-top: 27.1px;
      padding-bottom: 28.4px;
      border-top: solid 0.5px;
      border-bottom: solid 0.5px;
    }
    .flex_first_part h4 {
      color: #247f9f;
    }
    .flex_second_part {
      width: 439px;
      height: 290px;
      padding: 35px 49px 0px 25px;
      border-top: solid 10px;
      border-radius: 4px;
      box-shadow: var(--glb-shadow);
      background-image: linear-gradient(to bottom, #fefefe, #eceeed);
      h4 {
        cursor: pointer;
      }
    }
    .unlimited_devices {
      margin-top: 13.9px;
      margin-bottom: 12.6px;
      padding-top: 27.1px;
      padding-bottom: 28.4px;
      border-top: solid 0.5px;
      border-bottom: solid 0.5px;
    }
    .flex_second_part h4 {
      color: #247f9f;
    }
    .flex_third_part {
      width: 439px;
      height: 290px;
      padding: 35px 49px 0px 25px;
      border-top: solid 10px;
      border-radius: 4px;
      box-shadow: var(--glb-shadow);
      background-image: linear-gradient(to bottom, #fefefe, #eceeed);
      h4 {
        cursor: pointer;
      }
    }
    .private_devices {
      margin-top: 13.9px;
      margin-bottom: 12.6px;
      padding-top: 27.1px;
      padding-bottom: 28.4px;
      border-top: solid 0.5px;
      border-bottom: solid 0.5px;
    }
    .flex_third_part h4 {
      color: #247f9f;
    }
  }
}
@media (max-width: 786px) {
  .additionalinfo {
    width: 100%;
    .home_additionalinfo_flex_first {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .home_additionalinfo_first_div {
        h4 {
          //width: 70%;
          height: auto;
          font-size: 34px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .howItWorks{
          flex-wrap: wrap;
          .howItWorks-item{
            margin-bottom: 20px!important;
            h4{
              margin: 30px 62px;
            }
          }
        }

        .home_additionalinfo_second_div {
          width: 70%;
          height: max-content;
          font-size: 20px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 23px;
        }
        .home_additionalinfo_third_div {
          width: 30%;
          //height: 40px;
          //margin: 0 auto 0px auto;
          text-align: center;
          font-size: 16px;
          //padding: 20px 20px 0px 20px;
        }
        .home_additionalinfo_fourth_div {
          display: block;
          width: 80%;
          height: 241px;
          margin: 43px auto 63px auto;
        }
      }
    }
    .home_additionalinfo_flex_second {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      .home_additionalinfo_one_div {
        text-align: center;
        display: block;
        height: 241px;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
      }
      .home_additionalinfo_two_div {
        h4 {
          width: 70%;
          height: auto;
          font-size: 34px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .home_additionalinfo_three_div {
          width: 70%;
          height: max-content;
          font-size: 20px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0px;
        }
        .home_additionalinfo_four_div {
          width: 30%;
          height: 40px;
          margin: 23px auto 0px auto;
          text-align: center;
          font-size: 16px;
          padding: 20px 20px 0px 20px;
        }
      }
    }
  }
}

@media (min-width: 786px) and (max-width: 1215px) {
  .additionalinfo {
    .home_additionalinfo_flex_first {
      flex-direction: column;
      .home_additionalinfo_first_div {
        h4 {
          text-align: center;
          margin-top: 2.5rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .home_additionalinfo_second_div {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .home_additionalinfo_third_div {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
      }
      .home_additionalinfo_fourth_div {
        margin-top: 1rem;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .home_additionalinfo_flex_second {
      flex-direction: column;
      flex-flow: column-reverse;
      .home_additionalinfo_one_div {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .home_additionalinfo_two_div {
        h4 {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .home_additionalinfo_three_div {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .home_additionalinfo_four_div {
          text-align: center;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
