.completed-project-bug {
  background-color: var(--glb-bg-secondary);
  margin-left: -32px;
  margin-top: -32px;
  padding-left: 32px;
  padding-top: 32px;

  .heading {
    margin: 1.5rem 0;
  }
  .label {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #505050;
  }
  .completed-test-span {
    font-weight: lighter;
  }

  .completed-test-summary {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .completed-test-severity {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .completed-test-oem {
    max-width: 900px;
    height: 41px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  .completed-test-description {
    max-width: 900px;
    min-height: 188px;
    margin: 12px 0px 40px;
    padding: 20.5px 20px 0px 13.6px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #505050;
    background-color: #e6e6e6;
  }

  button {
    max-width: 502px;
    margin: 0px 0 20px;
    padding: 12px 184.4px 12px 184.5px;
    border-radius: 4px;
    border: dashed 1px #cfcfcf;
    color: #008080;
    margin: 12px 0 40px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  #getFile {
    display: none;
  }
}

@media screen and (max-width: 786px) {
  .completed-project-bug {
    margin: 0;
    padding: 0;
    .completed-test-status {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 15.4px 13px 13.6px;
      margin-bottom: 30px;
    }
    .completed-test-summary {
      display: flex;
      align-items: center;
      padding: 0px;
      padding-left: 15px;
      margin-bottom: 20px;
    }
    .completed-test-severity {
      display: flex;
      align-items: center;
      padding: 0px;
      padding-left: 15px;
      margin-bottom: 20px;
    }
    .completed-test-oem {
      display: flex;
      align-items: center;
      padding: 0px;
      padding-left: 15px;
      margin-bottom: 20px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      padding: 14px;
    }
  }
}
