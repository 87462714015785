.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  .forgot-password {
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2.5rem 3.5rem;
    width: 23%;
  }
}
@media (max-width: 786px) {
  .forgot-password-container {
    .forgot-password {
      width: 50%;
    }
  }
}
