.tools-used {
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tools-used-heading {
    margin: 0;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .tools-used-img {
    background-image: url("../../../assets/images/Mask Group 2.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 1053px;
    height: 203px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .tools-used-content {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #3d3d3d;
    margin-bottom: 60px;
    .tools-used-content-highlight {
      font-weight: bold;
    }
  }
  .tools-used-btn {
    width: 360px;
    // height: 54px;
    // padding: 20px 0px 0px 0px;
    // text-align: center;
    // font-size: 28px;
    // font-weight: 600;
    // border-radius: 4px;
    // background-color: var(--glb-btn-general);
    // color: var(--glb-txt-primary);
    // cursor: pointer;
  }
}
@media (max-width: 786px) {
  .tools-used {
    .tools-used-heading {
      width: 340px;
    }
    .tools-used-img {
      width: 350px;
      height: 66px;
    }
    .tools-used-content {
      width: 320px;
      height: 72px;
    }
    .tools-used-btn {
      width: 209px;
      //height: 43px;
      //padding: 16px 0px 0px 0px;
      text-align: center;
      font-size: 20px;
    }
  }
}
