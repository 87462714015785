.transfer-amt {
  .tester-bank-table {
    margin-bottom: 3rem;
  }
  .tester-initialized-transfer {
    margin: 3rem 0;
  }
  .current-bln-container {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    .transfer-amount {
      button {
        display: block;
        font-size: 1.2rem;
        border: none;
        padding: 1rem 5rem;
        text-align: center;
        margin-top: 1.8rem;
        border-radius: 4px;
        color: var(--glb-white);
        cursor: pointer;
        background-color: #008716;
        &:focus {
          outline: none;
        }
      }
    }
    .current-bln-label {
      font-size: 0.8rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #a4a4a4;
      margin-bottom: 0.5rem;
    }
    .current-bln {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .transfer-amt {
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #001464;
        margin-right: 0.7rem;
      }
      .messageinfo {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #505050;
        margin: 0.5rem 0.7rem;
      }
    }
  }
  .details {
    margin-top: 1rem;
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    font-weight: 500;
    .label-error {
      display: flex;
      flex-direction: row;
      column-gap: 17px;
    }
    .bank {
      padding: 0.7rem 1rem;
      margin-top: 0.7rem;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #cfcfcf;
      background-color: #f3f3f3;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
    }
    .hide {
      visibility: hidden;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    .show-error {
      color: #fc3232;
      font-size: 10px;
      padding-top: 0.3rem;
    }
    .add-name {
      grid-column: 1 / span 2;
      .name {
        box-sizing: border-box;
        padding: 0.7rem 1rem;
        margin-top: 0.7rem;
        width: 100%;
        border-radius: 4px;
        border: solid 1px #cfcfcf;
        background-color: #f3f3f3;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .submit-button {
    display: block;
    font-size: 1.2rem;
    border: none;
    padding: 1rem 5rem;
    text-align: center;
    margin-top: 1.8rem;
    border-radius: 4px;
    color: var(--glb-txt-primary);
    cursor: pointer;
    background-color: #008716;
    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 786px) {
  .transfer-amt {
    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .submit-button {
      width: 100%;
    }
    .current-bln-container {
      .transfer-amount {
        display: block;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
