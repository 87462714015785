.email-verification {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  .email-verification-container {
    width: 40%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: var(--glb-shadow);
    background-color: #ffffff;
    padding: 2rem;
    .email-verify-btn {
      width: 100%;
      border: none;
      border-radius: 4px;
      background-color: var(--glb-btn-general);
      font-size: 1.1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 0.5rem;
    }
  }
}

@media (max-width: 800px) {
  .email-verification {
    .email-verification-container {
      width: 60%;
    }
  }
}
@media (max-width: 450px) {
  .email-verification {
    .email-verification-container {
      width: 80%;
    }
  }
}
