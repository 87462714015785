.half-page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 65vh;
  // background: url("../../../assets/images/footer-bg.png") no-repeat center center/cover;
  background-color: var(--glb-bg-secondary);
  z-index: -1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .img {
    padding: 0 0.5rem;
    width: 13vw;
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .half-page-background {
    .img {
      display: none;
    }
  }
}
