.service-offerings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
  padding-top: 70px;
  .service-offerings-heading {
    margin: 0;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: var(--glb-dark-blue);
  }
  .service-offerings-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 187px;
    padding-top: 65px;
    .service-box {
      text-align: center;
      .service-box-heading {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: #5a5a5a;
      }
    }
  }
}
@media (max-width: 786px) {
  .service-offerings {
    width: 100%;
    .service-offerings-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 30px;
      column-gap: 115px;
    }
  }
}
